import { CallDictionaryKey, CallType, PackageUpgradeCallResponseValue } from '@crema/constants/AppEnums';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  FormControlLabel,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { GoPerson } from 'react-icons/go';
import { IoCall, IoCallOutline, IoClose } from 'react-icons/io5';

const ReceiveNewCallCardDialog = () => {
  const { user } = useAuthUser();
  const {
    inboundCallCardSubjectTypesList,
    outboundCallCardSubjectTypesList,
    packageTicketUpgradeValues,
    selectedCallDictionaryKey,
    receiveNewCallCard,
    openReceiveNewCallCardDialog,
    setOpenReceiveNewCallCardDialog,
    showSnackbar,
    handleCompletePackageUpgradeTicket,
    ticketDetails,
    userDetails,
  } = useGlobal();

  const [isLoading, setIsLoading] = useState(false);
  const [receiveNewCallCardNote, setReceiveNewCallCardNote] = useState('');

  const [selectedInboundCallCardSubject, setSelectedInboundCallCardSubject] = useState();
  const [selectedOutboundCallCardSubject, setSelectedOutboundCallCardSubject] = useState();
  const [selectedPackageTicketUpgradeValue, setSelectedPackageTicketUpgradeValue] = useState();
  const [assignToMeOnly, setAssignToMeOnly] = useState(false);

  const [receiveNewCallCardSubjectError, setReceiveNewCallCardSubjectError] = useState(false);

  const handleSaveReceiveNewCallCard = () => {
    if (
      (receiveNewCallCard.callType == CallType.Inbound && selectedInboundCallCardSubject == null) ||
      (receiveNewCallCard.callType == CallType.Outbound &&
        selectedCallDictionaryKey != CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket &&
        selectedOutboundCallCardSubject == null) ||
      (selectedCallDictionaryKey == CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket &&
        selectedPackageTicketUpgradeValue == null)
    ) {
      setReceiveNewCallCardSubjectError(true);
      return;
    }
    setIsLoading(true);
    setReceiveNewCallCardSubjectError(false);

    let saveCallCardData = {
      id: receiveNewCallCard.id,
    };

    if (receiveNewCallCard.callType === CallType.Inbound) {
      saveCallCardData.callSubject = selectedInboundCallCardSubject;
    } else if (receiveNewCallCard.callType === CallType.Outbound) {
      if (selectedCallDictionaryKey != CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket) {
        saveCallCardData.callSubject = selectedOutboundCallCardSubject;
      } else {
        // Upgrade Package
        saveCallCardData.callSubject = outboundCallCardSubjectTypesList[selectedCallDictionaryKey][0].ID;
      }
    }

    if (receiveNewCallCardNote !== '') {
      saveCallCardData.note = receiveNewCallCardNote;
    }

    const config = {
      method: 'post',
      url: `${process.env.React_App_Base_URL}/api/Call/save-call`,
      data: saveCallCardData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          if (selectedCallDictionaryKey == CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket) {
            handleCompletePackageUpgradeTicket(ticketDetails?.ID, selectedPackageTicketUpgradeValue, assignToMeOnly);
          }

          showSnackbar('Call Saved', 'success');
          setOpenReceiveNewCallCardDialog(false);
        } else {
          showSnackbar(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      anchor='right'
      open={openReceiveNewCallCardDialog}
      // onClose={() => setOpenReceiveNewCallCardDialog(false)}
      maxWidth='100%'
      maxHeight='100%'
    >
      <Box sx={{ width: '100%', overflow: 'auto', p: 4, minHeight: 300, minWidth: 500 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h3' sx={{ my: 1 }}>
            Call
          </Typography>
          {/* <IconButton
            aria-label='close'
            onClick={() => setOpenReceiveNewCallCardDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IoClose />
          </IconButton> */}
        </Box>
        <Divider sx={{ mt: 2 }} />
        {(receiveNewCallCard?.appUser?.phoneNumber || receiveNewCallCard?.appUser?.contactName) && (
          <Typography variant='h5' sx={{ mt: 3, fontWeight: '400' }}>
            Personal Information
          </Typography>
        )}

        {receiveNewCallCard?.appUser?.contactName && (
          <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <GoPerson style={{ width: '1.5em', height: '1.5em' }} />
            {receiveNewCallCard?.appUser?.contactName}
          </Typography>
        )}
        {receiveNewCallCard?.appUser?.phoneNumber && (
          <Typography variant='h5' sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <IoCallOutline style={{ width: '1.5em', height: '1.5em' }} />
            {receiveNewCallCard?.appUser?.phoneNumber}
          </Typography>
        )}

        {userDetails?.UserPBXCallPrompts.length > 0 && (
          <>
            <Typography variant='h5' sx={{ mt: 5, fontWeight: '400' }}>
              User Prompts
            </Typography>
            {userDetails?.UserPBXCallPrompts?.map((item) => (
              <Typography key={item.ID} variant='h5' sx={{ mt: 2 }}>
                {item.IVR.IVRTitle}: {item.Prompt.Texten}
              </Typography>
            ))}
          </>
        )}

        {selectedCallDictionaryKey == CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket && (
          <Typography variant='h5' sx={{ mt: 6 }}>
            <span style={{ fontWeight: 400 }}>Call Subject: </span>{' '}
            <span style={{ fontWeight: 600 }}>
              {outboundCallCardSubjectTypesList[selectedCallDictionaryKey][0].Text}
            </span>
          </Typography>
        )}
        {selectedCallDictionaryKey == CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket && (
          <Typography variant='h5' sx={{ mt: 4, fontWeight: '400' }}>
            Select Call Response
          </Typography>
        )}

        {selectedCallDictionaryKey != CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket && (
          <Typography variant='h5' sx={{ mt: 6, fontWeight: '400' }}>
            Select Call Subject
          </Typography>
        )}

        <Box sx={{ mt: 2 }}>
          {receiveNewCallCard.callType == CallType.Inbound && (
            <Box sx={{ my: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
              {inboundCallCardSubjectTypesList.map((InboundCallCardSubject, index) => (
                <Chip
                  key={index}
                  label={InboundCallCardSubject.Text}
                  variant='outlined'
                  size='large'
                  sx={{
                    background: InboundCallCardSubject.ID == selectedInboundCallCardSubject ? '#222B36' : '#fff',

                    color: InboundCallCardSubject.ID == selectedInboundCallCardSubject ? '#fff' : '#222B36',

                    '&.MuiChip-clickable:hover': {
                      background: InboundCallCardSubject.ID == selectedInboundCallCardSubject ? '#222B36' : '#0000000a',

                      color: InboundCallCardSubject.ID == selectedInboundCallCardSubject ? '#fff' : '#222B36',
                    },
                  }}
                  onClick={() => setSelectedInboundCallCardSubject(InboundCallCardSubject.ID)}
                />
              ))}
            </Box>
          )}

          {receiveNewCallCard.callType == CallType.Outbound &&
            selectedCallDictionaryKey != CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket && (
              <>
                <Box sx={{ my: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
                  {outboundCallCardSubjectTypesList[selectedCallDictionaryKey].map((OutboundCallCardSubject, index) => (
                    <Chip
                      key={index}
                      label={OutboundCallCardSubject.Text}
                      variant='outlined'
                      size='large'
                      sx={{
                        // height: '40px',
                        background: OutboundCallCardSubject.ID == selectedOutboundCallCardSubject ? '#222B36' : '#fff',

                        color: OutboundCallCardSubject.ID == selectedOutboundCallCardSubject ? '#fff' : '#222B36',

                        '&.MuiChip-clickable:hover': {
                          background: '#454F5B', // Change this to the desired hover background color
                          color: '#fff', // Change this to the desired hover text color
                        },
                      }}
                      onClick={() => setSelectedOutboundCallCardSubject(OutboundCallCardSubject.ID)}
                    />
                  ))}
                </Box>
                {!selectedOutboundCallCardSubject && receiveNewCallCardSubjectError && (
                  <FormHelperText sx={{ color: 'red', mt: 2 }}>Call Subject is Required</FormHelperText>
                )}
              </>
            )}

          {receiveNewCallCard.callType == CallType.Outbound &&
            selectedCallDictionaryKey == CallDictionaryKey.AdminSystemTicketSectionCallForUpgradeTicket && (
              <>
                <Box sx={{ my: 1, display: 'flex', gap: 2, alignItems: 'center' }}>
                  {packageTicketUpgradeValues.map((packageTicketUpgradeValue, index) => (
                    <Chip
                      key={index}
                      label={packageTicketUpgradeValue.Text}
                      variant='outlined'
                      size='large'
                      sx={{
                        // height: '40px',
                        background:
                          packageTicketUpgradeValue.Value == selectedPackageTicketUpgradeValue ? '#222B36' : '#fff',

                        color:
                          packageTicketUpgradeValue.Value == selectedPackageTicketUpgradeValue ? '#fff' : '#222B36',

                        '&.MuiChip-clickable:hover': {
                          background: '#454F5B', // Change this to the desired hover background color
                          color: '#fff', // Change this to the desired hover text color
                        },
                      }}
                      onClick={() => setSelectedPackageTicketUpgradeValue(packageTicketUpgradeValue.Value)}
                    />
                  ))}
                </Box>
                {selectedPackageTicketUpgradeValue ==
                  PackageUpgradeCallResponseValue.CustomerWillUpgradeThePackageLater && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: '#222B36',
                          '&.Mui-checked': {
                            color: '#222B36',
                          },
                        }}
                        checked={assignToMeOnly}
                        onChange={() => setAssignToMeOnly(!assignToMeOnly)}
                      />
                    }
                    label='Assign To Me Only'
                  />
                )}

                {!selectedPackageTicketUpgradeValue && receiveNewCallCardSubjectError && (
                  <FormHelperText sx={{ color: 'red', mt: 2 }}>Call Response is Required</FormHelperText>
                )}
              </>
            )}
        </Box>

        <TextField
          id='outlined-multiline-static'
          label='Note'
          multiline
          rows={4}
          value={receiveNewCallCardNote}
          onChange={(e) => setReceiveNewCallCardNote(e.target.value)}
          sx={{ width: '100%', mt: 6 }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            gap: 2,
          }}
        >
          {isLoading && (
            <Button
              variant='contained'
              disabled
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 4,
                width: '200px',
              }}
            >
              <CircularProgress size={18} color='inherit' />
              Save Call
            </Button>
          )}

          {!isLoading && (
            <Button
              onClick={handleSaveReceiveNewCallCard}
              type='submit'
              sx={{
                mt: 4,
                width: '150px',
                background: '#222B36',
                color: '#fff',
                '&:hover': {
                  background: '#222B36',
                  '& svg': {
                    color: '#fff',
                  },
                },
              }}
            >
              Save Call
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ReceiveNewCallCardDialog;
