import {
  CallSourcesConfiguration,
  CallStatusConfiguration,
  CallType,
} from '@crema/constants/AppEnums';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Divider,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { GoPerson } from 'react-icons/go';
import { IoCall, IoCallOutline, IoClose } from 'react-icons/io5';

const AddCreditDialog = () => {
  const { user } = useAuthUser();
  const {
    manualCreditPaymentTypeList,
    listingAndUserInfo,
    openAddCreditDialog,
    setOpenAddCreditDialog,
    showSnackbar,
    openListingDialog,
  } = useGlobal();

  const [isLoading, setIsLoading] = useState(false);

  const [addCreditNote, setAddCreditNote] = useState('');

  const [selectedManualPaymentType, setSelectedManualPaymentType] = useState();

  const [creditAmount, setCreditAmount] = useState('');

  const [addCreditError, setAddCreditError] = useState(false);

  const handleAddCreditToUser = () => {
    if (selectedManualPaymentType == null || creditAmount == '') {
      setAddCreditError(true);
      return;
    }
    setIsLoading(true);
    setAddCreditError(false);

    let addCreditData = {
      userID: listingAndUserInfo.AppUser.ID,
      credit: creditAmount,
      paymentType: selectedManualPaymentType,
    };

    if (addCreditNote !== '') {
      addCreditData.note = addCreditNote;
    }

    const config = {
      method: 'post',
      url: `${process.env.React_App_Base_URL}/api/Home/add-credit-to-user`,
      data: addCreditData,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.status == true) {
          openListingDialog(
            listingAndUserInfo.ID,
            listingAndUserInfo.PrivateAdTableTypeId,
          );

          showSnackbar('Credit Amount added to the user', 'success');
          setOpenAddCreditDialog(false);
        } else {
          showSnackbar(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        showSnackbar('Failed to Add Credit', 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      anchor='right'
      open={openAddCreditDialog}
      onClose={() => setOpenAddCreditDialog(false)}
      maxWidth='100%'
      maxHeight='100%'
    >
      <Box sx={{ width: '100%', overflow: 'auto', p: 4, minHeight: 300 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h3' sx={{ my: 1 }}>
            Add Credit
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => setOpenAddCreditDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <IoClose />
          </IconButton>
        </Box>
        <Divider sx={{ mt: 2 }} />

        <Typography variant='h5' sx={{ mt: 3, fontWeight: '400' }}>
          Personal Information
        </Typography>

        <Typography
          variant='h5'
          sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <GoPerson style={{ width: '1.5em', height: '1.5em' }} />
          {listingAndUserInfo?.AppUser.ContactName}
        </Typography>

        <Typography
          variant='h5'
          sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <IoCallOutline style={{ width: '1.5em', height: '1.5em' }} />
          {listingAndUserInfo?.AppUser.PhoneNumber}
        </Typography>

        <Typography variant='h5' sx={{ mt: 6, fontWeight: '400' }}>
          Select Payment Type
        </Typography>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
          {manualCreditPaymentTypeList.map((manualCreditPaymentType, index) => (
            <Chip
              key={index}
              label={manualCreditPaymentType.Text}
              variant='outlined'
              size='large'
              sx={{
                background:
                  manualCreditPaymentType.Value == selectedManualPaymentType
                    ? '#222B36'
                    : '#fff',

                color:
                  manualCreditPaymentType.Value == selectedManualPaymentType
                    ? '#fff'
                    : '#222B36',

                '&.MuiChip-clickable:hover': {
                  background:
                    manualCreditPaymentType.Value == selectedManualPaymentType
                      ? '#222B36'
                      : '#0000000a',

                  color:
                    manualCreditPaymentType.Value == selectedManualPaymentType
                      ? '#fff'
                      : '#222B36',
                },
              }}
              onClick={() =>
                setSelectedManualPaymentType(manualCreditPaymentType.Value)
              }
            />
          ))}
        </Box>
        {!selectedManualPaymentType && addCreditError && (
          <FormHelperText sx={{ color: 'red', mt: 2 }}>
            Payment Type is Required
          </FormHelperText>
        )}

        <TextField
          size='small'
          sx={{
            mt: 6,
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          name='numberformat'
          id='outlined-basic'
          label='Credit Amount'
          value={creditAmount || ''}
          onChange={(e) => {
            setCreditAmount(e.target.value);
          }}
          variant='outlined'
        />

        {creditAmount == '' && addCreditError && (
          <FormHelperText sx={{ color: 'red', mt: 2 }}>
            Credit Amount is Required
          </FormHelperText>
        )}

        <TextField
          id='outlined-multiline-static'
          label='Note'
          multiline
          rows={4}
          value={addCreditNote}
          onChange={(e) => setAddCreditNote(e.target.value)}
          sx={{ width: '100%', mt: 6 }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            gap: 2,
          }}
        >
          {isLoading && (
            <Button
              variant='contained'
              disabled
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: 4,
                width: '200px',
                gap: 2,
              }}
            >
              <CircularProgress size={18} color='inherit' />
              Confirm
            </Button>
          )}

          {!isLoading && (
            <Button
              onClick={handleAddCreditToUser}
              type='submit'
              sx={{
                mt: 4,
                width: '150px',
                background: '#222B36',
                color: '#fff',
                '&:hover': {
                  background: '#222B36',
                  '& svg': {
                    color: '#fff',
                  },
                },
              }}
            >
              Confirm
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddCreditDialog;
