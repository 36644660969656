import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { BsImages } from 'react-icons/bs';
// import { arrayMove } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { PropTypes } from 'prop-types';
import { IoMdCloseCircle, IoMdEye } from 'react-icons/io';
import IntlMessages from '@crema/helpers/IntlMessages';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import axios from 'axios';
import { NumericFormat } from 'react-number-format';
import { CurrencyType, CurrencyTypes } from '@crema/constants/AppEnums';
import CustomConfig from '@crema/constants/CustomConfig';
import { useDropzone } from 'react-dropzone';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import CustomImageEditor from '@crema/components/CustomComponents/CustomImageEditor/CustomImageEditor';
import { IoClose, IoWarningOutline } from 'react-icons/io5';
import { FaRegTrashCan, FaTrash } from 'react-icons/fa6';
import { PiTrashFill } from 'react-icons/pi';
import { FaRegTrashAlt, FaTrashAlt } from 'react-icons/fa';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

NumericFormatCustom.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
};

let temp = [];

const CarDetail = forwardRef((props, ref) => {
  const { listingDetails, saveLisitngError } = props;

  const { user } = useAuthUser();
  const { listingConfigurations } = useGlobal();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedPaintPartFiles, setSelectePaintPartFiles] = useState([]);

  const [brands, setBrands] = useState([]);
  const [brandValue, setBrandValue] = useState();

  const [locations, setLocations] = useState([]);
  const [locationValue, setLocationValue] = useState();

  const [models, setModels] = useState([]);
  const [modelValue, setModelValue] = useState();

  const [fullYears, setFullYears] = useState([]);
  const [years, setYears] = useState([]);
  const [yearValue, setYearValue] = useState();

  const [trims, setTrims] = useState([]);
  const [trimValue, setTrimValue] = useState();

  const [conditions, setconditions] = useState([]);
  const [conditionValue, setConditionValue] = useState();

  const [transmissions, setTransmissions] = useState([]);
  const [transmissionValue, setTransmissionValue] = useState();

  const [fuels, setFuels] = useState([]);
  const [fuelValue, setFuelValue] = useState([]);

  const [milages, setMilages] = useState([]);
  const [milageTypeValue, setMilageTypeValue] = useState();

  const [milageInput, setMilageInput] = useState();

  const [priceInput, setPriceInput] = useState();
  const [priceIQDInput, setPriceIQDInput] = useState();
  const [colors, setColors] = useState([]);
  const [colorValue, setColorValue] = useState();

  const [paintParts, setPaintParts] = useState([]);
  const [paintPartsValue, setPaintPartsValue] = useState();

  const [importCountries, setImportCountries] = useState([]);
  const [importCountryValue, setImportCountryValue] = useState();

  const [plateCities, setPlateCities] = useState([]);
  const [plateCityValue, setPlateCityValue] = useState();

  const [plateTypes, setPlateTypes] = useState([]);
  const [plateTypeValue, setPlateTypeValue] = useState('');

  const [plateNumber, setPlateNumber] = useState();
  const [vinNumber, setVinNumber] = useState();
  const [phoneNumber, setPhoneNumber] = useState();

  const [seatNumbers, setSeatNumbers] = useState([]);
  const [seatNumberValue, setSeatNumberValue] = useState();

  const [seatMaterials, setSeatMaterials] = useState([]);
  const [seatMaterialValue, setSeatMaterialValue] = useState();

  const [cylinders, setCylinders] = useState([]);
  const [cylinderValue, setCylinderValue] = useState();

  const [engines, setEngines] = useState([]);
  const [engineValue, setEngineValue] = useState();

  const [specifications, setSpecifications] = useState([]);
  const [specificationValue, setSpecificationValue] = useState([]);

  const [description, setDescription] = useState();

  const [warranty, setWarranty] = useState(false);
  const [showPrice, setShowPrice] = useState(false);

  const [currencyTypeValue, setCurrencyTypeValue] = useState(1);

  const [vinPhotoName, setVinPhotoName] = useState('');
  const [vinPhotoFile, setVinPhotoFile] = useState({
    url: null,
    file: null,
  });
  const [deleteVinFile, setDeleteVinFile] = useState(false);
  const [AttachesToBeDeleted, setAttachesToBeDeleted] = useState([]);
  const [paintPartAttachesToBeDeleted, setPaintPartAttachesToBeDeleted] = useState([]);

  const [confirmDialog, setConfirmDialog] = useState({ open: false, index: null });
  const [openVinPhotDialog, setOpenVinPhotoDialog] = useState(false);

  useImperativeHandle(ref, () => ({
    getAllData: () => {
      return {
        selectedFiles,
        AttachesToBeDeleted,
        selectedPaintPartFiles,
        paintPartAttachesToBeDeleted,
        vinPhotoFile,
        vinNumber,
        deleteVinFile,
        phoneNumber,
        brandValue,
        modelValue,
        yearValue,
        locationValue,
        trimValue,
        conditionValue,
        transmissionValue,
        fuelValue,
        milageTypeValue,
        milageInput,
        priceInput,
        priceIQDInput,
        colorValue,
        paintPartsValue,
        importCountryValue,
        plateCityValue,
        plateTypeValue,
        plateNumber,
        seatNumberValue,
        seatMaterialValue,
        cylinderValue,
        engineValue,
        specificationValue,
        description,
        warranty,
        showPrice,
        currencyTypeValue,
      };
    },
  }));

  useEffect(() => {
    if (listingDetails) {
      setBrandValue({
        value: listingDetails.Brand.ID,
        label: listingDetails.Brand['BrandName' + 'en'],
      });

      setLocationValue({
        value: listingDetails.Location.ID,
        label: listingDetails.Location['LocationName' + 'en'],
      });
      setModelValue(listingDetails?.Model?.ID);
      setYearValue(listingDetails.Year?.ID);
      setTrimValue(listingDetails.ModelSFX?.ID);
      setConditionValue(listingDetails.CarCondition?.ID);
      setTransmissionValue(listingDetails.Transmission?.ID);
      setEngineValue(listingDetails.Engine?.ID);
      setMilageInput(listingDetails?.VisitedKm);
      setMilageTypeValue(listingDetails?.MilageId);
      setCylinderValue(listingDetails.Cylinder?.ID);
      setColorValue(listingDetails.Color?.ID);
      setPaintPartsValue(listingDetails.PaintPart?.ID);
      setVinNumber(listingDetails?.VinNumber);
      // setVinPhotoFile(listingDetails?.VinPath)
      setVinPhotoName(listingDetails?.VinPath);
      setImportCountryValue(listingDetails.ImportCountry?.ID);
      setSeatNumberValue(listingDetails.SeatNumber?.ID);
      setSeatMaterialValue(listingDetails.SeatMaterial?.ID);
      setPlateCityValue(listingDetails.PlateCity?.ID);
      setPlateTypeValue(listingDetails.Plate?.ID);
      setPlateNumber(listingDetails?.PlateNumber);
      setPhoneNumber(listingDetails?.PhoneNumber);
      setDescription(listingDetails?.Description);
      setShowPrice(listingDetails.PricePublishing);
      setWarranty(listingDetails.OfficialWarranty);
      setCurrencyTypeValue(listingDetails?.PriceUnit);
      setFuelValue(listingDetails?.CarFuelIds);
      setSpecificationValue(listingDetails?.CarListSpecificationIds);
      setPriceInput(listingDetails?.Price);
      setPriceIQDInput(listingDetails?.PriceIQD);

      //attachments

      let tempAttachments = [];
      listingDetails?.Attachments.forEach((carAttachment) => {
        let img = {
          name: carAttachment.FileName,
          mode: 'online',
          ID: carAttachment.ID,
          url: CustomConfig.imagePathUrl + carAttachment.CardUrl,
          detailUrl: CustomConfig.imagePathUrl + carAttachment.Url,
          original: CustomConfig.imagePathUrl + carAttachment.OrginalUrl,
        };
        tempAttachments.push(img);
      });
      setSelectedFiles(tempAttachments);

      let tempPainPartAttachments = [];
      listingDetails?.PaintPartAttachments.forEach((paintPartAttachment) => {
        let img = {
          name: paintPartAttachment.FileName,
          mode: 'online',
          ID: paintPartAttachment.ID,
          url: CustomConfig.imagePathUrl + paintPartAttachment.Url,
        };
        tempPainPartAttachments.push(img);
      });
      setSelectePaintPartFiles(tempPainPartAttachments);
    }
  }, [listingDetails]);

  const handleChange = (event, setInputState) => {
    const {
      target: { value },
    } = event;
    setInputState(value);
  };

  const handleDropDownChange = (event, setDropDownState) => {
    const {
      target: { value },
    } = event;

    setDropDownState(value);
  };

  const handleExtraDropDownChange = (event, setDropDownState) => {
    const {
      target: { value },
    } = event;
    setDropDownState(value);
  };

  useEffect(() => {
    const locations = listingConfigurations.Locations;
    setLocations(
      locations?.map((location) => ({
        value: location.ID,
        label: location['LocationName' + 'en'],
      })),
    );

    const brands = listingConfigurations.Brands;
    setBrands(
      brands.map((brand) => ({
        value: brand.ID,
        label: brand['BrandName' + 'en'],
        models: brand.Models,
      })),
    );
    let filteredBrand;
    filteredBrand = brands.find((brand) => brand.ID === listingDetails?.Brand?.ID);
    if (filteredBrand) {
      setModels(
        filteredBrand.Models.map((model) => ({
          value: model.ID,
          text: model['ModelName' + 'en'],
          FromYear: model.FromYear,
          ToYear: model.ToYear,
          ModelSFXes: model.ModelSFXes,
        })),
      );
    }
    const years = listingConfigurations.Years;
    setFullYears(
      years.map((year) => ({
        value: year.ID,
        text: year['YearName'],
      })),
    );
    const conditions = listingConfigurations.CarConditions;
    setconditions(
      conditions.map((condition) => ({
        value: condition.ID,
        text: condition['CarConditionName' + 'en'],
      })),
    );
    const transmissions = listingConfigurations.Transmissions;
    setTransmissions(
      transmissions.map((transmission) => ({
        value: transmission.ID,
        text: transmission['TransmissionName' + 'en'],
      })),
    );
    const fuels = listingConfigurations.Fuels;
    setFuels(
      fuels.map((fuel) => ({
        value: fuel.ID,
        text: fuel['FuelName' + 'en'],
      })),
    );
    const paintparts = listingConfigurations.PaintParts;
    setPaintParts(
      paintparts.map((paintpart) => ({
        value: paintpart.ID,
        text: paintpart['PaintPartName' + 'en'],
      })),
    );
    const importcountries = listingConfigurations.ImportCountries;
    setImportCountries(
      importcountries.map((importcountry) => ({
        value: importcountry.ID,
        text: importcountry['ImportCountryName' + 'en'],
      })),
    );
    const platecities = listingConfigurations.PlateCities;
    setPlateCities(
      platecities.map((platecity) => ({
        value: platecity.ID,
        text: platecity['PlateCityName' + 'en'],
      })),
    );
    const platetypes = listingConfigurations.PlateTypes;
    setPlateTypes(
      platetypes.map((platetype) => ({
        value: platetype.ID,
        text: platetype['PlateName' + 'en'],
      })),
    );
    const seatnumbers = listingConfigurations.SeatNumbers;
    setSeatNumbers(
      seatnumbers.map((seatnumber) => ({
        value: seatnumber.ID,
        text: seatnumber['SeatNumberName' + 'en'],
      })),
    );
    const seatmaterials = listingConfigurations.SeatMaterials;
    setSeatMaterials(
      seatmaterials.map((seatmaterial) => ({
        value: seatmaterial.ID,
        text: seatmaterial['SeatMaterialName' + 'en'],
      })),
    );
    const milages = listingConfigurations.MilageTypes;
    setMilages(
      milages.map((milage) => ({
        value: milage.ID,
        text: milage['MilageName' + 'en'],
      })),
    );
    const colors = listingConfigurations.Colors;
    setColors(
      colors.map((color) => ({
        value: color.ID,
        text: color['ColorName' + 'en'],
        colorCode: color.ColorCode,
      })),
    );
  }, []);

  const loadCylinderEngineSpecifications = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/Home/cylinder-and-engine-and-specification`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user.Token,
      },
      params: {
        ModelId: modelValue ?? 0,
        SFXId: trimValue ?? 0,
      },
    };

    axios(config)
      .then((response) => {
        const responseData = response.data;

        const cylinders = responseData.Cylinders;
        setCylinders(
          cylinders.map((cylinder) => ({
            value: cylinder.ID,
            text: cylinder['CylinderName' + 'en'],
          })),
        );

        const engines = responseData.Engines;
        setEngines(
          engines.map((engine) => ({
            value: engine.ID,
            text: engine['EngineName' + 'en'],
          })),
        );

        const specifications = responseData.Specifications;

        setSpecifications(
          specifications.map((specification) => ({
            value: specification.ID,
            text: specification['Specification' + 'en'],
          })),
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const loadDataModels = () => {
    let filteredBrand;

    if (brandValue && brandValue.value > 0) {
      filteredBrand = brands.find((brand) => brand.value === brandValue.value);
    } else if (listingDetails && listingDetails.Brand && listingDetails.Brand.ID > 0) {
      filteredBrand = brands.find((brand) => brand.value === listingDetails.Brand.ID);
    }

    if (filteredBrand) {
      setModels(
        filteredBrand.models.map((model) => ({
          value: model.ID,
          text: model['ModelName' + 'en'],
          FromYear: model.FromYear,
          ToYear: model.ToYear,
          ModelSFXes: model.ModelSFXes,
        })),
      );
    } else {
      setModels([]);
    }
  };

  useEffect(() => {
    if (modelValue || trimValue) {
      loadCylinderEngineSpecifications();
    }
  }, [modelValue, trimValue]);

  useEffect(() => {
    if (brandValue || listingDetails?.Brand?.ID > 0) {
      loadDataModels();
    }
  }, [brandValue, listingDetails]);

  useEffect(() => {
    let filteredModel;
    filteredModel = models.find((model) => model.value === modelValue);

    if (!filteredModel?.FromYear && !filteredModel?.ToYear) {
      setYears(fullYears);
    } else if (filteredModel?.FromYear && !filteredModel?.ToYear) {
      const returnYears = fullYears.filter((year) => year.text > filteredModel.FromYear.YearName);

      setYears(returnYears);
    } else {
      const returnYears = fullYears.filter(
        (year) => year.YearName > filteredModel.FromYear.YearName && year.YearName < filteredModel.ToYear.YearName,
      );
      setYears(returnYears);
    }
  }, [modelValue, models]);

  useEffect(() => {
    setTrims([]);
    if (!(yearValue > 0 || listingDetails?.YearId > 0)) {
      return;
    }

    if (years.length > 0) {
      let sfxData = [];
      let changedFilterYears = years;
      let year = changedFilterYears?.filter((year) => year.value == yearValue || year.value == listingDetails?.YearId);
      year = Number(year[0]?.text);

      models
        .filter((model) => model.value == modelValue || model.value == listingDetails?.Model?.ID)[0]
        ?.['ModelSFXes']?.forEach((s) => {
          let from = 0;
          let to = 0;
          try {
            from = +s['FromYear']?.['YearName'] || 0;
          } catch (exception) {
            console.log(exception);
          }
          try {
            to = +s['ToYear']?.['YearName'] || 0;
          } catch (exception) {
            console.log(exception);
          }

          // If FromYear and ToYear are null, add the SFX
          if (from === 0 && to === 0) {
            sfxData.push(s);
          } else if ((from === 0 || year >= from) && (to === 0 || year <= to)) {
            sfxData.push(s);
          }
        });

      setTrims(
        sfxData.map((sfx) => ({
          value: sfx.ID,
          text: sfx.SFXName,
        })),
      );
    }
  }, [modelValue, yearValue, listingDetails, models, years]);

  const renderOptions = (items) => {
    return items?.map((item) => (
      <MenuItem key={item.value} value={item.value}>
        {item.text}
      </MenuItem>
    ));
  };
  const renderColorOptions = (items) => {
    return items.map((item) => {
      const commonStyles = {
        bgcolor: item.colorCode,
        borderColor: '#ccc',
        mx: 1,
        border: 1,
        width: '.9rem',
        height: '.9rem',
      };
      return (
        <MenuItem key={item.value} value={item.value} sx={{ background: '#fff' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {item.text}
            <Box sx={item.colorCode && { ...commonStyles }} />
          </div>
        </MenuItem>
      );
    });
  };

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const resizeFile = async (file, uuid, callback) => {
    var fr = new FileReader();
    fr.onload = function () {
      var size = 4000;
      var image = new Image();
      image.src = fr.result;
      image.onload = function (imageEvent) {
        let width = image.width,
          height = image.height;
        var ratio = height / width;
        if (width > size) {
          width = size;
          height = ratio * size;
        }

        var canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);

        var dataUrl = canvas.toDataURL(file.type, 92 / 100);

        var resizedImage = dataURItoBlob(dataUrl);
        callback(resizedImage, uuid);
      };
    };
    fr.readAsDataURL(file);
  };

  function generateUUID() {
    var d = new Date().getTime();
    var d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  const onDropGallery = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let uuid = generateUUID();
      resizeFile(file, uuid, (resizedImage, uuidP) => {
        let img = {
          name: file.name,
          uuid: uuidP,
          url: URL.createObjectURL(resizedImage),
          file: new File([resizedImage], file.name),
          mode: 'local',
        };
        setSelectedFiles((prevState) => [...prevState, img]);
        URL.revokeObjectURL(file); // Revoke original file URL to avoid memory leaks
      });
    });
  }, []);

  const onSortEnd = (oldIndex, newIndex) => {
    setSelectedFiles((array) => arrayMove(array, oldIndex, newIndex));
  };

  const openConfirmDialog = (index) => {
    setConfirmDialog({ open: true, index });
  };

  const closeConfirmDialog = () => {
    setConfirmDialog({ open: false, index: null });
  };

  const removeSelectedFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, fileIndex) => fileIndex !== index);
    if (selectedFiles[index].mode === 'online') {
      setAttachesToBeDeleted((prevState) => [...prevState, selectedFiles[index].ID]);
    }
    setSelectedFiles(updatedFiles);
    closeConfirmDialog();
  };

  const onDropVinPhoto = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setVinPhotoName(file.name);
      setVinPhotoFile({
        url: URL.createObjectURL(file),
        file: file,
      });
    }
  }, []);

  const removeVinPhoto = () => {
    setVinPhotoName('');
    setDeleteVinFile(true);
    setVinPhotoFile({
      url: null,
      file: null,
    });
  };

  //gallery
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropGallery,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg', '.jfif'],
      'image/svg+xml': ['.svg'],
      'image/gif': ['.gif'],
    },
    multiple: true,
  });

  //vinphoto
  const { getRootProps: getVinRootProps, getInputProps: getVinInputProps } = useDropzone({
    onDrop: onDropVinPhoto,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg', '.jfif'],
      'image/svg+xml': ['.svg'],
      'image/gif': ['.gif'],
    },
    multiple: false,
  });

  const updateImage = (imageBlob, updatedImageIndex) => {
    let updatedImages = [...selectedFiles]; // Create a shallow copy of the selectedFiles array
    let uuid = generateUUID();
    let img = {
      name: selectedFiles[updatedImageIndex].name,
      uuid: uuid,
      url: URL.createObjectURL(imageBlob),
      file: new File([imageBlob], selectedFiles[updatedImageIndex].name),
      mode: 'local',
    };
    if (selectedFiles[updatedImageIndex].mode === 'online') {
      setAttachesToBeDeleted((prevState) => [...prevState, selectedFiles[updatedImageIndex].ID]);
    }
    updatedImages.splice(updatedImageIndex, 1, img); // Replace the item at the index with the updated image
    setSelectedFiles(updatedImages);

    URL.revokeObjectURL(imageBlob); // Revoke the original file URL to avoid memory leaks
  };

  // const updateImage = (imageBlob, updatedImageIndex) => {
  //   let updatedImages = [];
  //   selectedFiles.forEach((imageItem, index) => {
  //     if (index == updatedImageIndex) {
  //       let uuid = generateUUID();
  //       resizeFile(imageBlob, uuid, (resizedImage, uuidP) => {
  //         let img = {
  //           name: imageItem.name,
  //           uuid: uuidP,
  //           url: URL.createObjectURL(resizedImage),
  //           file: new File([resizedImage], imageItem.name),
  //           mode: 'local',
  //         };
  //         updatedImages.push(img);
  //         URL.revokeObjectURL(imageBlob); // Revoke original file URL to avoid memory leaks
  //       });
  //     } else {
  //       updatedImages.push(imageItem);
  //     }
  //   });
  //   setSelectedFiles(updatedImages);
  // };
  const [dialogImageEditor, setDialogImagedEditor] = useState({
    open: false,
    photo: null,
    index: null,
  });
  const renderPhotos = (source, getInputProps, getRootProps) => {
    return (
      <>
        {source.map((photo, index) => (
          <SortableItem key={photo.name}>
            <div
              className='sortable-item'
              style={{
                width: '120px',
                position: 'relative',
                borderRadius: '7px',
                cursor: 'grab',
                userSelect: 'none',
                zIndex: 1300,
                display: dialogImageEditor.open ? 'none' : 'inline-block',
              }}
            >
              <img
                id='addcar-img'
                src={photo.url || 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921'}
                alt=''
                onClick={() => {
                  setDialogImagedEditor({
                    open: true,
                    photo: photo,
                    index: index,
                  });
                }} // Open dialog on click
              />

              <IconButton
                aria-label='close'
                onClick={() => openConfirmDialog(index)}
                sx={{
                  position: 'absolute',
                  right: '3px',
                  top: '3px',
                  padding: '7px',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  background: '#fcecec',
                  color: '#E72929',
                  '&:hover': {
                    background: '#fcecec',
                    '& svg': {
                      color: '#fff',
                    },
                  },
                }}
              >
                <FaTrashAlt
                  style={{
                    color: '#E72929',
                    width: '14px',
                    height: '14px',
                  }}
                />
              </IconButton>
            </div>
          </SortableItem>
        ))}

        <Dialog anchor='right' open={confirmDialog.open} onClose={closeConfirmDialog} maxWidth='100%' maxHeight='100%'>
          <Box sx={{ minWidth: 500 }}>
            <DialogTitle sx={{ m: 0, p: 3 }} id='customized-dialog-title'>
              <Typography variant='h3' sx={{ my: 1 }}>
                Delete photo
              </Typography>
            </DialogTitle>
            <IconButton
              aria-label='close'
              onClick={closeConfirmDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <IoClose />
            </IconButton>

            <DialogContent dividers sx={{ minHeight: 150 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    background: '#eee',
                    width: '60px',
                    height: '60px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IoWarningOutline style={{ color: '#222B36', width: '40px', height: '40px' }} />
                </Box>

                <Typography variant='h4' sx={{ mt: 3, fontWeight: '500' }}>
                  Are you sure want to delete the photo?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: 2,
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    p: 2,
                  }}
                >
                  <Button
                    onClick={() => removeSelectedFile(confirmDialog.index)}
                    type='submit'
                    sx={{
                      width: '150px',
                      background: '#222B36',
                      color: '#fff',
                      '&:hover': {
                        background: '#222B36',
                        '& svg': {
                          color: '#fff',
                        },
                      },
                    }}
                  >
                    Yes
                  </Button>

                  <Button
                    onClick={closeConfirmDialog}
                    type='submit'
                    sx={{
                      width: '150px',
                      background: '#fff',
                      color: '#222B36',
                      border: '1px solid #ccc',
                      '&:hover': {
                        background: '#fff',
                        '& svg': {
                          color: '#222B36',
                        },
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </Box>
        </Dialog>

        <Dialog
          open={dialogImageEditor.open}
          sx={{
            zIndex: 1200,
            overflow: 'none',
          }}
          PaperProps={{
            style: {
              width: '100%', // Set your desired width
              height: '99%', // Set your desired height
              maxWidth: 'none', // Disable the default maxWidth
              maxHeight: 'none', // Disable the default maxHeight
              overflow: 'none',
              m: 0,
            },
          }}
        >
          <DialogContent
            sx={{
              padding: '0',
            }}
          >
            {dialogImageEditor.photo && (
              <CustomImageEditor
                image={dialogImageEditor.photo}
                updateImage={updateImage}
                setDialogImagedEditor={setDialogImagedEditor}
                index={dialogImageEditor.index}
              />
            )}
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 5,
              WebkitMarginStart: '100px',
              mb: 2,
            }}
          >
            <Button
              disabled={dialogImageEditor.index == 0}
              onClick={() => {
                let prevIndex = dialogImageEditor.index - 1;

                setDialogImagedEditor({
                  open: true,
                  photo: selectedFiles[prevIndex],
                  index: prevIndex,
                });
              }}
              type='submit'
              sx={{
                width: '150px',
                height: '40px',
                background: '#fff',
                color: '#222B36',
                border: '1px solid #ccc',
                '&:hover': {
                  background: '#fff',
                },
              }}
            >
              <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                {dialogImageEditor.index != 0 && (
                  <GrLinkPrevious
                    style={{
                      width: '1.3em',
                      height: '1.3em',
                      color: '#E72929',
                    }}
                  />
                )}

                <Typography variant='h4'>Prev</Typography>
              </Box>
            </Button>
            <Button
              type='submit'
              onClick={() => {
                const saveImageButton = document
                  .getElementsByClassName('FIE_topbar-save-wrapper')[0]
                  .querySelector('button');
                let milliSeconds = 100;
                if (saveImageButton && !saveImageButton.disabled) {
                  saveImageButton.click();
                } else {
                  milliSeconds = 0;
                }
                setTimeout(() => {
                  if (dialogImageEditor.index == selectedFiles.length - 1) {
                    setDialogImagedEditor({
                      open: false,
                      photo: null,
                      index: null,
                    });
                  } else {
                    let nextIndex = dialogImageEditor.index + 1;

                    setDialogImagedEditor({
                      open: true,
                      photo: selectedFiles[nextIndex],
                      index: nextIndex,
                    });
                  }
                }, milliSeconds);
              }}
              sx={{
                width: '150px',
                height: '40px',
                background: dialogImageEditor.index == selectedFiles.length - 1 ? '#222b36' : '#fff',

                border: dialogImageEditor.index == selectedFiles.length - 1 ? '1px solid #222b36' : '1px solid #ccc',
                '&:hover': {
                  background: dialogImageEditor.index == selectedFiles.length - 1 ? '#222b36' : '#fff',
                },
              }}
            >
              {dialogImageEditor.index == selectedFiles.length - 1 ? (
                <Typography variant='h4' sx={{ color: '#fff' }}>
                  Done
                </Typography>
              ) : (
                <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <Typography variant='h4' sx={{ color: '#222B36' }}>
                    Next
                  </Typography>
                  <GrLinkNext style={{ width: '1.3em', height: '1.3em' }} />
                </Box>
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <div className='label' {...getRootProps()}>
          <input {...getInputProps()} />

          <BsImages style={{ width: '2em', height: '2em' }} />
        </div>
      </>
    );
  };

  const onDropPaintParts = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let uuid = generateUUID();
      let img = {
        name: file.name,
        uuid: uuid,
        url: URL.createObjectURL(file),
        file: file,
        mode: 'local',
      };
      setSelectePaintPartFiles((prevState) => [...prevState, img]);
    });
  }, []);

  const { getRootProps: getPaintPartRootProps, getInputProps: getPaintPartInputProps } = useDropzone({
    onDrop: onDropPaintParts,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg', '.jfif'],
      'image/svg+xml': ['.svg'],
      'image/gif': ['.gif'],
    },
    multiple: true,
  });

  const [paintpartImageDialog, setPaintpartImageDialog] = useState({
    open: false,
    photo: null,
    index: null,
  });
  const renderPainPartPhotos = (source) => {
    return (
      <>
        {source?.map((photo, index) => {
          const key = photo.uuid || index; // Use a stable unique identifier if available
          return (
            <SortableItem key={key} index={index}>
              <div
                className='sortable-item'
                style={{
                  width: '120px',
                  position: 'relative',
                  borderRadius: '7px',
                  cursor: 'grab',
                  userSelect: 'none',
                }}
              >
                <img
                  id='paintpart-img'
                  src={
                    photo.url || 'https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif?20151024034921'
                  }
                  alt='paintpart'
                  onClick={() => {
                    setPaintpartImageDialog({
                      open: true,
                      photo: photo,
                      index: index,
                    });
                  }}
                />

                <IconButton
                  aria-label='close'
                  onClick={() => removePaintPartSelectedFile(index)}
                  sx={{
                    position: 'absolute',
                    right: '3px',
                    top: '3px',
                    padding: '7px',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    background: '#fcecec',
                    color: '#E72929',
                    '&:hover': {
                      background: '#fcecec',
                      '& svg': {
                        color: '#fff',
                      },
                    },
                  }}
                >
                  <FaTrashAlt
                    style={{
                      color: '#E72929',
                      width: '14px',
                      height: '14px',
                    }}
                  />
                </IconButton>
              </div>
            </SortableItem>
          );
        })}
        <Dialog
          key={paintpartImageDialog.index}
          open={paintpartImageDialog.open}
          onClose={() => {
            setPaintpartImageDialog({
              open: false,
              photo: null,
              index: null,
            });
          }}
        >
          <Box
            sx={{
              px: 4,
              py: 4,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h3'>Paint Part Image</Typography>
              <IconButton
                aria-label='close'
                onClick={() =>
                  setPaintpartImageDialog({
                    open: false,
                    photo: null,
                    index: null,
                  })
                }
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <IoClose />
              </IconButton>
            </Box>
            <Divider sx={{ my: 4 }} />
            <Box>
              <img
                src={paintpartImageDialog?.photo?.url}
                alt=''
                style={{
                  width: '100%',
                  height: '500px',
                  objectFit: 'cover',
                }}
              />
            </Box>
          </Box>
        </Dialog>
        <div className='paintpart-label' {...getPaintPartRootProps()}>
          <input {...getPaintPartInputProps()} />

          <BsImages style={{ width: '2em', height: '2em' }} />
        </div>
      </>
    );
  };

  const removePaintPartSelectedFile = (index) => {
    const updatedFiles = selectedPaintPartFiles.filter((_, fileIndex) => fileIndex !== index);
    if (selectedPaintPartFiles[index]?.mode === 'online') {
      setPaintPartAttachesToBeDeleted((prevState) => [...prevState, selectedPaintPartFiles[index].ID]);
    }
    setSelectePaintPartFiles(updatedFiles);
  };

  const onSortEndPaintPart = (oldIndex, newIndex) => {
    removePaintPartSelectedFile((array) => arrayMove(array, oldIndex, newIndex));
  };

  function getVinPhotoName(imagePath, maxLength = 22) {
    let imageName;

    if (imagePath?.includes('\\')) {
      const parts = imagePath.split('\\');
      imageName = parts[parts.length - 1];
    } else {
      imageName = imagePath;
    }

    if (imageName?.length > maxLength) {
      return imageName.slice(0, maxLength) + '...';
    } else {
      return imageName;
    }
  }

  return (
    <Box sx={{ minHeight: 700 }}>
      <div className='app' style={{ borderRadius: '7px' }}>
        {/* <Typography variant='h3'>Gallery</Typography> */}

        <div style={{ borderRadius: '7px' }} className='result'>
          <SortableList className='sortable-list' draggedItemClassName='dragged' onSortEnd={onSortEnd}>
            {renderPhotos(selectedFiles, getInputProps, getRootProps)}
          </SortableList>
        </div>
      </div>

      <Grid container spacing={4} sx={{ mt: 3 }}>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
          >
            <Autocomplete
              size='small'
              disablePortal
              disableClearable={true}
              id='combo-box-demo'
              value={brandValue || ''}
              onChange={(event, value) => {
                setBrandValue(value);
                setModelValue();
                setYearValue();
                setTrimValue();
              }}
              options={brands}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              // getOptionLabel={(option) => option?.label}
              renderInput={(params) => <TextField {...params} label='Select Brand' />}
            />
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Model</InputLabel>
            <Select
              name='models'
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              value={models.some((model) => model.value === modelValue) ? modelValue : ''}
              onChange={(e) => {
                handleDropDownChange(e, setModelValue);
                setYearValue();
                setTrimValue();
              }}
              input={<OutlinedInput label='Select Model' />}
              MenuProps={MenuProps}
            >
              {renderOptions(models)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Year Model</InputLabel>
            <Select
              name='years'
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              value={years.some((year) => year.value === yearValue) ? yearValue : ''}
              onChange={(e) => handleDropDownChange(e, setYearValue)}
              input={<OutlinedInput label='Select Year Model' />}
              MenuProps={MenuProps}
            >
              {renderOptions(years)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-simple-select-required-label'>Select Trim</InputLabel>
            <Select
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              name='trim'
              value={trims.some((trim) => trim.value === trimValue) ? trimValue : ''}
              onChange={(e) => handleDropDownChange(e, setTrimValue)}
              input={<OutlinedInput label='Select Trim' />}
              MenuProps={MenuProps}
            >
              {renderOptions(trims)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
          >
            <Autocomplete
              size='small'
              disablePortal
              id='combo-box-demo'
              disableClearable={true}
              value={locationValue || ''}
              onChange={(event, value) => {
                setLocationValue(value);
              }}
              options={locations ?? []}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              // getOptionLabel={(option) => option?.label}
              renderInput={(params) => <TextField {...params} label='Select Location' />}
            />
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Condition</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='condition'
              value={conditionValue || ''}
              onChange={(e) => handleDropDownChange(e, setConditionValue)}
              input={<OutlinedInput label='Select Condition' />}
              MenuProps={MenuProps}
            >
              {renderOptions(conditions)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              backgroundColor: '#fff',
              width: '100%',
              borderRadius: '7px',
            }}
          >
            <TextField
              size='small'
              id='outlined-basic'
              label='Milage'
              name='milagenumber'
              InputProps={{
                inputComponent: NumericFormatCustom,
              }}
              value={milageInput || ''}
              onChange={(e) => handleChange(e, setMilageInput)}
              variant='outlined'
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-simple-select-required-label'>Select Milage Type</InputLabel>
            <Select
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              name='milagetype'
              value={milageTypeValue || ''}
              onChange={(e) => handleDropDownChange(e, setMilageTypeValue)}
              autoWidth
              label='Select Milage Type'
            >
              {renderOptions(milages)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Paint Part</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='paintpart'
              value={paintPartsValue || ''}
              onChange={(e) => handleDropDownChange(e, setPaintPartsValue)}
              input={<OutlinedInput label='Select Paint Part' />}
              MenuProps={MenuProps}
            >
              {renderOptions(paintParts)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Transmission</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='transmission'
              value={transmissionValue || ''}
              onChange={(e) => handleDropDownChange(e, setTransmissionValue)}
              input={<OutlinedInput label='Select Transmission' />}
              // MenuProps={MenuProps}
            >
              {renderOptions(transmissions)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Fuel Type</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='fuel'
              multiple
              value={fuelValue || []}
              onChange={(e) => handleDropDownChange(e, setFuelValue)}
              input={<OutlinedInput label='Select Fuel Type' />}
              MenuProps={MenuProps}
              error={fuelValue?.length == 0 && saveLisitngError}
              renderValue={(selected) => {
                return fuels
                  ?.filter((fuel) => selected?.includes(fuel.value))
                  .map((fuel) => fuel.text)
                  .join(', ');
              }}
            >
              {fuels?.map((fuel) => (
                <MenuItem key={fuel.value} value={fuel.value} name={fuel.text} sx={{ p: 1 }}>
                  <Checkbox checked={fuelValue?.includes(fuel.value)} />
                  <ListItemText primary={fuel.text} />
                </MenuItem>
              ))}
            </Select>
            {fuelValue?.length == 0 && saveLisitngError && (
              <FormHelperText style={{ color: 'red' }}> Fuel is Required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Engine Size</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='engine'
              value={engines.some((engine) => engine.value === engineValue) ? engineValue : ''}
              onChange={(e) => handleDropDownChange(e, setEngineValue)}
              input={<OutlinedInput label='Select Engine Size' />}
              MenuProps={MenuProps}
            >
              {renderOptions(engines)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Cylinder</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='cylinders'
              value={cylinders.some((cylinder) => cylinder.value === cylinderValue) ? cylinderValue : ''}
              onChange={(e) => handleDropDownChange(e, setCylinderValue)}
              input={<OutlinedInput label='Select Cylinder' />}
              MenuProps={MenuProps}
            >
              {renderOptions(cylinders)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Color</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='color'
              value={colorValue || ''}
              onChange={(e) => handleDropDownChange(e, setColorValue)}
              input={<OutlinedInput label='Select Color' />}
              MenuProps={MenuProps}
            >
              {renderColorOptions(colors)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              backgroundColor: '#fff',
              borderRadius: '7px',
              width: '100%',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Import Country</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='country'
              value={importCountryValue || ''}
              onChange={(e) => handleDropDownChange(e, setImportCountryValue)}
              input={<OutlinedInput label='Select Import Country' />}
              MenuProps={MenuProps}
            >
              {renderOptions(importCountries)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            label='Warranty'
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
              border: '1px solid #C4C4C4',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#222B36',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#222B36',
                    },
                  }}
                  size='small'
                  onChange={() => setWarranty(!warranty)}
                  checked={warranty}
                />
              }
              label='Official'
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '35px',
                WebkitMarginStart: '8px',
                color: 'grey',
                width: '100%',
              }}
            />
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Number of Seats</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='numberseats'
              value={seatNumberValue || ''}
              onChange={(e) => handleDropDownChange(e, setSeatNumberValue)}
              input={<OutlinedInput label='Select Number of Seats' />}
              MenuProps={MenuProps}
            >
              {renderOptions(seatNumbers)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Seat Material</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='seatmaterial'
              value={seatMaterialValue || ''}
              onChange={(e) => handleDropDownChange(e, setSeatMaterialValue)}
              input={<OutlinedInput label='Select Seat Material' />}
              MenuProps={MenuProps}
            >
              {renderOptions(seatMaterials)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Plate City</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='platecity'
              value={plateCityValue || ''}
              onChange={(e) => handleDropDownChange(e, setPlateCityValue)}
              input={<OutlinedInput label='Select Plate City' />}
              MenuProps={MenuProps}
            >
              {renderOptions(plateCities)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Plate Type</InputLabel>
            <Select
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='platetype'
              value={plateTypeValue || ''}
              onChange={(e) => handleDropDownChange(e, setPlateTypeValue)}
              input={<OutlinedInput label='Select Plate Type' />}
              MenuProps={MenuProps}
            >
              {renderOptions(plateTypes)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-name-label'>Select Currency Type</InputLabel>
            <Select
              defaultValue=''
              labelId='demo-multiple-name-label'
              id='demo-multiple-name'
              name='currency'
              value={currencyTypeValue || ''}
              onChange={(e) => {
                handleDropDownChange(e, setCurrencyTypeValue);
                setPriceInput();
                setPriceIQDInput();
              }}
              input={<OutlinedInput label='Select Currency Type' />}
              MenuProps={MenuProps}
            >
              {CurrencyTypes.map((currency) => (
                <MenuItem key={currency.ID} sx={{ background: '#fff' }} value={currency.ID}>
                  {currency.Text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={2}>
          {currencyTypeValue == CurrencyType.Dollar && (
            <FormControl
              sx={{
                backgroundColor: '#fff',
                // width: {xs: '48%', md: '49%'},
                width: '100%',
                borderRadius: '7px',
              }}
            >
              <TextField
                size='small'
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                    display: 'none',
                  },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                  inputComponent: NumericFormatCustom,
                }}
                name='numberformat'
                id='outlined-basic'
                label='Dollar'
                value={priceInput || ''}
                onChange={(e) => {
                  setPriceInput(e.target.value);
                }}
                variant='outlined'
              />
            </FormControl>
          )}
          {currencyTypeValue == CurrencyType.IraqiDinar && (
            <>
              <FormControl
                sx={{
                  backgroundColor: '#fff',
                  width: '100%',
                  borderRadius: '7px',
                }}
              >
                <TextField
                  size='small'
                  sx={{
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                      display: 'none',
                    },
                    '& input[type=number]': {
                      MozAppearance: 'textfield',
                    },
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>IQD</InputAdornment>,
                    inputComponent: NumericFormatCustom,
                  }}
                  name='numberformat'
                  id='outlined-basic'
                  label='Iraqi Dinar'
                  value={priceIQDInput || ''}
                  onChange={(e) => {
                    setPriceIQDInput(e.target.value);
                  }}
                  variant='outlined'
                />
              </FormControl>
            </>
          )}
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              border: '1px solid #C4C4C4',
              borderRadius: '7px',
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#222B36',
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                      backgroundColor: '#222B36',
                    },
                  }}
                  size='small'
                  onChange={(e) => setShowPrice(!showPrice)}
                  checked={showPrice}
                />
              }
              label='Show Price'
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '35px',
                WebkitMarginStart: '8px',
                color: 'grey',
              }}
            />
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              backgroundColor: '#fff',
              width: '100%',
              borderRadius: '7px',
            }}
          >
            <TextField
              size='small'
              id='outlined-basic'
              label='Plate Number'
              value={plateNumber ?? ''}
              onChange={(e) => handleChange(e, setPlateNumber)}
              name='platenumber'
              variant='outlined'
            />
          </FormControl>
        </Grid>
        <Grid item lg={3}>
          <Box
            sx={{
              width: '100%',
              borderRadius: '7px',
              border: '1px solid #cccccc',
              height: '38px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            {vinPhotoName && vinPhotoName != '' ? (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: 3,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <BsImages
                    style={{
                      width: '1.3em',
                      height: '1.3em',
                    }}
                  />
                  {getVinPhotoName(vinPhotoName)}
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <IoMdEye
                    onClick={() => setOpenVinPhotoDialog(true)}
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      cursor: 'pointer',
                    }}
                  />
                  <IoMdCloseCircle
                    onClick={removeVinPhoto}
                    style={{
                      width: '1.4em',
                      height: '1.4em',
                      cursor: 'pointer',
                      color: '#E72929',
                    }}
                  />
                </Box>
              </Box>
            ) : (
              <div className='vin-dropzone-container' {...getVinRootProps()}>
                <input {...getVinInputProps()} />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 2,
                    cursor: 'pointer',
                    px: 3,
                  }}
                >
                  <BsImages
                    style={{
                      width: '1.3em',
                      height: '1.3em',
                    }}
                  />
                  Upload Vin Photo
                </Box>
              </div>
            )}
          </Box>
          <Dialog
            open={openVinPhotDialog}
            onClose={() => {
              setOpenVinPhotoDialog(false);
            }}
          >
            <Box
              sx={{
                px: 4,
                py: 4,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h3'>Vin Photo</Typography>
                <IconButton
                  aria-label='close'
                  onClick={() => setOpenVinPhotoDialog(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <IoClose />
                </IconButton>
              </Box>
              <Divider sx={{ my: 4 }} />
              <Box>
                <img
                  src={vinPhotoFile.url || CustomConfig.imagePathUrl + vinPhotoName}
                  alt='vin-photo'
                  style={{
                    width: '100%',
                    height: '500px',
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Box>
          </Dialog>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              backgroundColor: '#fff',
              width: '100%',
              borderRadius: '7px',
            }}
          >
            <TextField
              size='small'
              id='outlined-basic'
              label='Vin Number'
              value={vinNumber ?? ''}
              onChange={(e) => handleChange(e, setVinNumber)}
              name='vinnumber'
              variant='outlined'
            />
          </FormControl>
        </Grid>
        <Grid item lg={2}>
          <FormControl
            sx={{
              backgroundColor: '#fff',
              width: '100%',
              borderRadius: '7px',
            }}
          >
            <TextField
              size='small'
              id='outlined-basic'
              label='Public Phone Number'
              value={phoneNumber ?? ''}
              onChange={(e) => handleChange(e, setPhoneNumber)}
              name='publicphonenumber'
              variant='outlined'
            />
          </FormControl>
        </Grid>
        <Grid item lg={5}>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: '#fff',
              borderRadius: '7px',
            }}
            size='small'
          >
            <InputLabel id='demo-multiple-checkbox-label'>Select Extra Specification</InputLabel>
            <Select
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              name='extraspecs'
              multiple
              value={specificationValue || []}
              onChange={(e) => {
                handleExtraDropDownChange(e, setSpecificationValue);
              }}
              input={<OutlinedInput label='Select Extra Specification' />}
              renderValue={(selected) => {
                const maxDisplay = 5;
                const displayedSpecs = specifications
                  ?.filter((spec) => selected.includes(spec.value))
                  .slice(0, maxDisplay);

                const moreSpecsCount = selected.length - maxDisplay;

                return (
                  <Box
                    sx={{
                      display: 'block',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '100%',
                    }}
                  >
                    {displayedSpecs.map((spec) => spec.text).join(', ')}
                    {moreSpecsCount > 0 && `, +${moreSpecsCount} more...`}
                  </Box>
                );
              }}
              MenuProps={MenuProps}
            >
              {specifications?.map((spec) => (
                <MenuItem key={spec.value} value={spec.value} name={spec.text} sx={{ p: 1 }}>
                  <Checkbox checked={specificationValue?.includes(spec.value)} />
                  <ListItemText primary={spec.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 3 }}>
        <Grid item xs={5}>
          <TextField
            // size='small'
            sx={{
              backgroundColor: '#fff',
              width: '100%',
              borderRadius: '7px',
            }}
            id='outlined-multiline-static'
            label='Description'
            name='description'
            value={description ?? ''}
            onChange={(e) => handleChange(e, setDescription)}
            multiline
            rows={5}
          />
        </Grid>
        <Grid item xs={7}>
          <Box
            sx={{
              borderRadius: '7px',

              width: '100%',
              border: '1px solid #c4c4c4',
              textAlign: 'center',
              p: 2,
            }}
          >
            <Typography variant='h5'>Paint Parts</Typography>

            <div style={{ borderRadius: '7px' }} className='result'>
              <SortableList className='sortable-list' onSortEnd={onSortEndPaintPart}>
                {renderPainPartPhotos(selectedPaintPartFiles, getPaintPartInputProps, getPaintPartRootProps)}
              </SortableList>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
});

export default CarDetail;
CarDetail.displayName = 'CarDetail';
CarDetail.propTypes = {
  listingDetails: PropTypes.object,
  saveLisitngError: PropTypes.bool,
};
