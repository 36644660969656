import React from 'react';
import { BiAlignLeft } from 'react-icons/bi';
import { HiUserGroup, HiUsers } from 'react-icons/hi2';
import { PermissionKeys, RoutePermittedRole } from '@crema/constants/AppEnums';
import { BsPersonFillGear, BsPersonFillLock } from 'react-icons/bs';
import { MdPendingActions } from 'react-icons/md';
import { TbListDetails } from 'react-icons/tb';
import { GoTasklist } from 'react-icons/go';
import { RiListSettingsLine } from 'react-icons/ri';

const routesConfig = [
  {
    id: 'app',
    title: 'Sample',
    messageId: 'sidebar.overview',
    type: 'group',
    children: [
      {
        id: 'manageaccess',
        title: 'Manage Access',
        messageId: 'sidebar.manageaccess',
        requireSuperAdmin: true,
        type: 'collapse',
        icon: <BsPersonFillGear />,
        children: [
          {
            id: 'users',
            title: 'Users',
            messageId: 'sidebar.users',
            permitedPermissionKey: PermissionKeys.Prodcut.Read,
            requireSuperAdmin: true,
            type: 'item',
            icon: <HiUsers />,
            url: '/manage-access/users',
          },
          {
            id: 'agents',
            title: 'Agents',
            messageId: 'sidebar.agents',
            type: 'item',
            icon: <HiUsers />,
            url: '/manage-access/agents',
          },
          {
            id: 'roles',
            title: 'Roles',
            messageId: 'sidebar.roles',
            type: 'item',
            icon: <BsPersonFillLock />,
            url: '/manage-access/roles',
          },
          {
            id: 'assignroles',
            title: 'Assign Roles',
            messageId: 'sidebar.assignroles',
            type: 'item',
            icon: <BsPersonFillLock />,
            url: '/manage-access/assign-roles',
          },
          {
            id: 'assigngroups',
            title: 'Ticket Assign Groups',
            permitedPermissionKey: PermissionKeys.Prodcut.Read,
            requireSuperAdmin: false,
            messageId: 'sidebar.assigngroups',
            type: 'item',
            icon: <HiUserGroup />,
            url: '/manage-access/assign-groups',
          },
        ],
      },
      {
        id: 'managelistings',
        title: 'Manage Listings',
        messageId: 'sidebar.managelistings',
        permitedPermissionKey: PermissionKeys.ManageListingsPermissionGroup.ViewManageListingsPage,
        requireSuperAdmin: false,
        type: 'item',
        icon: <RiListSettingsLine />,
        url: '/manage-listings',
      },
      // {
      //   id: 'manageshowrooms',
      //   title: 'Manage Showrooms',
      //   messageId: 'sidebar.manageshowrooms',
      //   // permitedPermissionKey: PermissionKeys.ManageListingsPermissionGroup.ViewManageListingsPage,
      //   requireSuperAdmin: false,
      //   type: 'item',
      //   icon: <RiListSettingsLine />,
      //   url: '/manage-showrooms',
      // },
      {
        id: 'Tickets',
        title: 'Tickets',
        messageId: 'sidebar.tickets',
        permitedPermissionKey:
          PermissionKeys.TicketPermissionGroup.ReadTodoTickets ||
          PermissionKeys.TicketPermissionGroup.ReadCompletedTickets ||
          PermissionKeys.TicketPermissionGroup.ViewMissedCallTickets,
        requireSuperAdmin: false,
        type: 'item',
        icon: <GoTasklist />,
        url: '/tickets/tasks',
      },
      {
        id: 'CRM Pending Listings',
        title: 'CRM Pending Listings',
        messageId: 'sidebar.crmpendinglistings',
        requireSuperAdmin: false,
        permitedPermissionKey: PermissionKeys.CRMListingsPermissionGroup.ViewCRMListingsPage,
        type: 'item',
        icon: <MdPendingActions />,
        url: '/crm/pending-listings',
      },
      {
        id: 'Editor Pending Listings',
        title: 'Editor Pending Listings',
        messageId: 'sidebar.editorpendinglistings',
        requireSuperAdmin: false,
        permitedPermissionKey: PermissionKeys.EditorPendingListingsPermissionGroup.ViewPendingListingsPage,
        type: 'item',
        icon: <MdPendingActions />,
        url: '/editor/pending-listings',
      },
    ],
  },
];
export default routesConfig;
