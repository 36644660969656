import AppLoader from '@crema/components/AppLoader';
import {
  AdminSystemStatus,
  CallDictionaryKey,
  ChangeStatusType,
  checkListingStatus,
  CheckTicketSubType,
  CurrencyType,
  determineBackgroundColor,
  determineName,
  getStatusColor,
  GroupAssignment,
  PermissionKeys,
  PrivateAdDraftTypes,
  PrivateAdTableType,
  PrivateAdTypes,
  TicketAssignmentType,
  TicketAssignmentTypes,
  TicketCommentAdminTypes,
  TicketSourceOperatorTypes,
  TicketSubTypes,
} from '@crema/constants/AppEnums';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';

import { CgNotes } from 'react-icons/cg';
import { IoCalendarNumberOutline, IoCall, IoCallOutline, IoClose, IoCopyOutline } from 'react-icons/io5';
import { LiaWalletSolid } from 'react-icons/lia';
import { useParams } from 'react-router-dom';
import CarDetail from './CarDetail';
import './ListingDetail.css';
import ListingHistory from './ListingHistory';
import { IoMdAdd, IoMdTime } from 'react-icons/io';
import CustomConfig from '@crema/constants/CustomConfig';
import CallHistory from './CallHistory';

import { LocalizationProvider, MobileDateTimePicker, StaticDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useGlobal } from '@crema/context/AppContextProvider/GlobalContext';
import { FaCheckCircle } from 'react-icons/fa';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { SlLocationPin } from 'react-icons/sl';
import { GoPerson } from 'react-icons/go';
import { CiGlobe } from 'react-icons/ci';
import { CommaSeparator } from '@crema/helpers/Extensions';
import { Helmet } from 'react-helmet';
import { PiMapPinLight, PiNotepadLight } from 'react-icons/pi';
import { useUserPermissions } from '@crema/context/AppContextProvider/PermissionContextProvider';
import { UserHasPermission } from '@crema/helpers/RouteHelper';

dayjs.extend(utc);

const ListingDetail = () => {
  const {
    listingDetails,
    closeListingDialog,
    setListingDetails,
    setLockListingID,
    setLockListingData,
    setlistingAndUserUserInfo,
    setOpenManualCallCardDialog,
    setOpenAddCreditDialog,
    showSnackbar,
    listingConfigurations,
    setSelectedCallDictionaryKey,
    groupAssignmentsWithUsers,
  } = useGlobal();

  const { handleReloadCRMBoardListings } = useGlobal();
  const { userPermissions } = useUserPermissions();
  const { user } = useAuthUser();

  const firstRender = useRef(true);

  const hasEditPermission = UserHasPermission(
    PermissionKeys.CRMListingsPermissionGroup.EditCRMListingsDetails,
    userPermissions,
  );

  const hasChangeFollowUpAssignmentPermission = UserHasPermission(
    PermissionKeys.CRMListingsPermissionGroup.ChangeFollowUpAssignment,
    userPermissions,
  );

  const hasPublishPermission = UserHasPermission(
    PermissionKeys.CRMListingsPermissionGroup.PublishListingToPending,
    userPermissions,
  );

  const hasCallThroughCallCenterPermission = UserHasPermission(
    PermissionKeys.GeneralPermissionGroup.MakeCallThroughCallCenter,
    userPermissions,
  );

  const hasCreateManualCallCardPermission = UserHasPermission(
    PermissionKeys.GeneralPermissionGroup.CreateManualCallCard,
    userPermissions,
  );

  const hasAddCreditToUserPermission = UserHasPermission(
    PermissionKeys.GeneralPermissionGroup.AddCreditToUser,
    userPermissions,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPublishButton, setIsLoadingPublishButton] = useState(false);
  const [publishListingError, setPublishListingError] = useState(false);

  const [isLoadingCallButton, setIsLoadingCallButton] = useState(false);
  const [isLoadingSaveOnlyButton, setIsLoadingSaveOnlyButton] = useState(false);
  const [openDiscardDialog, setOpenDiscardDialog] = useState(false);
  const [openCheckLaterDialog, setOpenCheckLaterDialog] = useState(false);
  const [openPublishStatusDialog, setOpenPublishStatusDialog] = useState(false);

  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);

  const [openPublishConfirmationDialog, setOpenPublishConfirmationDialog] = useState(false);

  const [listingHistory, setLisitingHistory] = useState([]);
  const [callHistory, setCallHistory] = useState([]);

  const [checkLaterStatusConfig, setCheckLaterStatusConfig] = useState([]);
  const [discardStatusConfig, setDiscardStatusConfig] = useState([]);
  const [selectedCheckLaterStatus, setSelectedCheckLaterStatus] = useState();

  const [publishRejectedListingComment, setPublishRejectedListingComment] = useState('');

  const [checkLaterAssignments, setCheckLaterAssignments] = useState({
    assignmentTypeId: TicketAssignmentType.Group,
    applicationUserId: '',
    assignedGroupId: GroupAssignment.CRM,
  });

  const crmGroup = groupAssignmentsWithUsers.find((group) => group.GroupId === GroupAssignment.CRM);
  const crmUsers = crmGroup ? crmGroup.Users : [];

  const [checkLaterError, setCheckLaterError] = useState(false);

  const [selectedDiscardStatus, setSelectedDiscardStatus] = useState();
  const [discardStatusError, setDiscardStatusError] = useState(false);

  const [taskStartDate, setTaskStartDate] = useState(dayjs().add(1, 'hour'));
  const [note, setNote] = useState('');

  const [privateListingType, setPrivateListingType] = useState();

  const [publishStatus, setPublishStatus] = useState();

  const carDetailRef = useRef();

  const [saveLisitngError, setSaveListingError] = useState(false);

  const handleTypeChange = (newTypeId) => {
    setCheckLaterAssignments((prevAssignments) => {
      const newState = {
        ...prevAssignments,
        assignmentTypeId: newTypeId,
        applicationUserId: newTypeId === TicketAssignmentType.User ? prevAssignments.applicationUserId : undefined,
        assignedGroupId: newTypeId === TicketAssignmentType.Group ? prevAssignments.assignedGroupId : undefined,
      };

      return Object.fromEntries(Object.entries(newState).filter(([_, v]) => v !== 0));
    });
  };

  const handleSelectionChange = (newValue) => {
    const newId = checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User ? newValue?.Id : newValue?.ID;

    setCheckLaterAssignments((prevAssignments) => {
      const newState = {
        ...prevAssignments,
        [checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User
          ? 'applicationUserId'
          : 'assignedGroupId']: newId,

        [checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User
          ? 'assignedGroupId'
          : 'applicationUserId']: undefined,
      };

      return Object.fromEntries(Object.entries(newState).filter(([_, v]) => v !== 0));
    });
  };

  const handleOnlySaveButton = () => {
    setIsLoadingSaveOnlyButton(true);
    if (carDetailRef.current) {
      const carDetailData = carDetailRef.current.getAllData();

      handleSaveOnly(carDetailData, 'saveOnly');
    }
  };

  const handleSaveOnly = (carDetailData, type) => {
    if (carDetailData.fuelValue.length == 0 || listingDetails.ID == null) {
      setSaveListingError(true);
      setIsLoadingSaveOnlyButton(false);
      showSnackbar('Fuel Type is Required', 'warning');

      return;
    }

    setSaveListingError(false);

    var formData = new FormData();
    const now = new Date();
    const formattedUTCString = now.toISOString().slice(0, 19);

    carDetailData.fuelValue.forEach((id) => {
      formData.append('CarFuelIds', id);
    });

    carDetailData.specificationValue?.length > 0 &&
      carDetailData.specificationValue.forEach((id) => {
        formData.append('CarListSpecificationIds', id);
      });

    formData.append('SaveDate', formattedUTCString);

    formData.append('LocationId', carDetailData.locationValue.value);
    formData.append('AppUserId', listingDetails.AppUser.ID);
    formData.append('ID', listingDetails.ID);

    if (carDetailData.plateNumber && carDetailData.plateNumber != '') {
      formData.append('PlateNumber', carDetailData.plateNumber);
    }

    if (carDetailData.phoneNumber && carDetailData.phoneNumber != '') {
      formData.append('PhoneNumber', carDetailData.phoneNumber);
    }

    if (carDetailData.milageTypeValue) {
      formData.append('MilageId', carDetailData.milageTypeValue);
    }

    if (carDetailData.paintPartsValue) {
      formData.append('PaintPartId', carDetailData.paintPartsValue);
    }

    if (carDetailData.seatMaterialValue) {
      formData.append('SeatMaterialId', carDetailData.seatMaterialValue);
    }

    if (carDetailData.plateTypeValue) {
      formData.append('PlateId', carDetailData.plateTypeValue);
    }

    if (carDetailData.plateCityValue) {
      formData.append('PlateCityId', carDetailData.plateCityValue);
    }

    if (carDetailData.brandValue) {
      formData.append('BrandId', carDetailData.brandValue.value);
    }

    if (carDetailData.seatNumberValue) {
      formData.append('SeatNumberId', carDetailData.seatNumberValue);
    }

    if (carDetailData.colorValue) {
      formData.append('ColorId', carDetailData.colorValue);
    }

    formData.append('CarCategoryId', 1);

    if (carDetailData.conditionValue) {
      formData.append('CarConditionId', carDetailData.conditionValue);
    }

    if (carDetailData.importCountryValue) {
      formData.append('ImportCountryId', carDetailData.importCountryValue);
    }

    if (carDetailData.modelValue) {
      formData.append('ModelId', carDetailData.modelValue);
    }

    if (carDetailData.trimValue) {
      formData.append('ModelSFXId', carDetailData.trimValue);
    }

    if (carDetailData.transmissionValue) {
      formData.append('TransmissionId', carDetailData.transmissionValue);
    }

    if (carDetailData.cylinderValue) {
      formData.append('CylinderId', carDetailData.cylinderValue);
    }

    if (carDetailData.engineValue) {
      formData.append('EngineId', carDetailData.engineValue);
    }

    formData.append('OfficialWarranty', carDetailData.warranty);

    if (carDetailData.yearValue) {
      formData.append('YearId', carDetailData.yearValue);
    }

    if (carDetailData.milageInput && carDetailData.milageInput != '') {
      formData.append('VisitedKm', carDetailData.milageInput);
    }

    formData.append('PricePublishing', carDetailData.showPrice);

    if (carDetailData.currencyTypeValue) {
      formData.append('PriceUnit', carDetailData.currencyTypeValue);
    }

    if (carDetailData.currencyTypeValue == CurrencyType.Dollar && carDetailData.priceInput) {
      formData.append('Price', carDetailData.priceInput);
    }

    if (carDetailData.currencyTypeValue == CurrencyType.IraqiDinar && carDetailData.priceIQDInput) {
      formData.append('PriceIQD', carDetailData.priceIQDInput);
    }

    if (carDetailData.description && carDetailData.description != '') {
      formData.append('Description', carDetailData.description);
    }
    if (carDetailData.vinPhotoFile.file) {
      formData.append('Vinfile', carDetailData.vinPhotoFile.file);
    } else if (carDetailData.vinPhotoFile.file == null && carDetailData.deleteVinFile == true) {
      formData.append('DeleteVinRequest', carDetailData.deleteVinFile);
    }

    if (carDetailData.vinNumber && carDetailData.vinNumber != '') {
      formData.append('VinNumber', carDetailData.vinNumber);
    }

    if (carDetailData.selectedFiles != null && carDetailData.selectedFiles.length > 0) {
      carDetailData.selectedFiles.forEach((ele, index) => {
        if (ele.mode != 'online') {
          formData.append('ImageOrder[' + index + '].' + 'ID', 0);
          formData.append('ImageOrder[' + index + '].' + 'Sort', index);
          formData.append('ImageOrder[' + index + '].' + 'File', ele['file']);
          formData.append('ImageOrder[' + index + '].' + 'FileName', ele['file'].name);
        } else {
          formData.append('ImageOrder[' + index + '].' + 'ID', ele.ID);
          formData.append('ImageOrder[' + index + '].' + 'Sort', index);
          formData.append('ImageOrder[' + index + '].' + 'File', null);
        }
      });
    }

    if (carDetailData.selectedPaintPartFiles != null && carDetailData.selectedPaintPartFiles.length > 0) {
      carDetailData.selectedPaintPartFiles
        .filter((ele) => ele.mode != 'online')
        .forEach((ele, index) => {
          formData.append('PaintPartFiles', ele['file']);
        });
    }

    if (carDetailData.AttachesToBeDeleted != null && carDetailData.AttachesToBeDeleted.length > 0) {
      carDetailData.AttachesToBeDeleted.forEach((attachmentID) => {
        formData.append('AttachesToBeDeleted[]', attachmentID);
      });
    }

    if (carDetailData.paintPartAttachesToBeDeleted != null && carDetailData.paintPartAttachesToBeDeleted.length > 0) {
      carDetailData.paintPartAttachesToBeDeleted.forEach((paintPartattachmentID) => {
        formData.append('PaintPartAttachesToBeDeleted[]', paintPartattachmentID);
      });
    }

    // formData.forEach((val, key) => {
    //   console.log(val, key);
    // });

    let requestUrlSave;
    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      requestUrlSave = `${process.env.React_App_Base_URL}/api/CRMPendingListing/draft-save-only`;
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      requestUrlSave = `${process.env.React_App_Base_URL}/api/CRMPendingListing/listing-save-only`;
    }

    const config = {
      method: 'post',
      url: requestUrlSave,
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        setIsLoadingSaveOnlyButton(false);
        if (response.data.status == true) {
          if (type == 'publish') {
            handlePublish();
          }
          if (type == 'saveOnly') {
            // closeListingDialog();
            handleReloadCRMBoardListings();
            showSnackbar('Changes has been saved', 'success');
          }
        } else {
          setIsLoadingSaveOnlyButton(false);
          setIsLoadingPublishButton(false);
          showSnackbar(response.data.message, 'error');
        }
      })
      .catch((error) => {
        setIsLoadingSaveOnlyButton(false);
        setIsLoadingPublishButton(false);
        console.log(error);
        showSnackbar('Save only failed', 'error');
      });
  };

  const handleLoadPublishStatus = () => {
    let requestLoadPublishStatus;

    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      requestLoadPublishStatus = `${process.env.React_App_Base_URL}/api/CRMPendingListing/load-publish-status-draft`;
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      requestLoadPublishStatus = `${process.env.React_App_Base_URL}/api/CRMPendingListing/load-publish-status-listing`;
    }

    const config = {
      method: 'get',
      url: requestLoadPublishStatus,
      params: {
        id: listingDetails.ID,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setPublishStatus(response.data);
          setOpenPublishStatusDialog(true);

          //show modal
        } else {
          showSnackbar('Failed to Load Publish Status', 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        showSnackbar('Failed to Load Publish Status', 'error');
      });
  };

  const handlePublish = () => {
    if (privateListingType == null) {
      setPublishListingError(true);
      setIsLoadingPublishButton(false);
      return;
    }
    setPublishListingError(false);

    let requestUrlPublish;
    let payloadDataPublish;

    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      requestUrlPublish = `${process.env.React_App_Base_URL}/api/CRMPendingListing/publish-draft`;
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      requestUrlPublish = `${process.env.React_App_Base_URL}/api/CRMPendingListing/publish-listing`;
    }

    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      payloadDataPublish = {
        DraftPrivateCarId: listingDetails.ID,
        PublishPrivateAdTypeId: privateListingType,
      };
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      payloadDataPublish = {
        CarId: listingDetails.ID,
        PublishPrivateAdTypeId: privateListingType,
      };

      if (publishRejectedListingComment !== '') {
        payloadDataPublish.PublishRejectedListingComment = publishRejectedListingComment;
      }
    }

    const config = {
      method: 'post',
      url: requestUrlPublish,
      data: payloadDataPublish,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: user?.Token,
      },
    };

    // make axios post request
    axios(config)
      .then((response) => {
        setIsLoadingPublishButton(false);
        if (response.data.status == true) {
          closeListingDialog();
          setOpenPublishConfirmationDialog(false);
          setOpenPublishStatusDialog(false);
          handleReloadCRMBoardListings();
          // setListingDetails();
          setLisitingHistory([]);
          setCallHistory([]);
          setLockListingID(null);
          setLockListingData(null);
          showSnackbar('Listing has been publish to Editor', 'success');
        } else {
          showSnackbar(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingPublishButton(false);

        showSnackbar(error.response.data.message, 'error');
      });
  };

  const handleCheckLater = () => {
    if (selectedCheckLaterStatus == null || taskStartDate == null) {
      setCheckLaterError(true);
      return;
    }
    setIsLoading(true);
    setCheckLaterError(false);

    let requestUrlCheckLater;
    let payloadDataCheckLater;

    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      requestUrlCheckLater = `${process.env.React_App_Base_URL}/api/CRMPendingListing/draft-change-status`;
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      requestUrlCheckLater = `${process.env.React_App_Base_URL}/api/CRMPendingListing/listing-change-status`;
    }

    payloadDataCheckLater = {
      id: listingDetails.ID,
      changedTime: new Date(),
      changeStatusType: ChangeStatusType.CheckLater,
      ticketSubType: selectedCheckLaterStatus,
      taskStartDate: taskStartDate,
      ticketAssignments: [checkLaterAssignments],
    };

    if (note !== '') {
      payloadDataCheckLater.ticketDescription = note;
    }

    const config = {
      method: 'post',
      url: requestUrlCheckLater,
      data: payloadDataCheckLater,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.status == true) {
          showSnackbar('Listing sent to Follow up Board', 'success');
          setIsLoading(false);
          setOpenCheckLaterDialog(false);
          closeListingDialog();
          handleReloadCRMBoardListings();
          // setListingDetails();
          setLisitingHistory([]);
          setCallHistory([]);
          setLockListingID(null);
          setLockListingData(null);
        } else {
          showSnackbar(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        showSnackbar(error.response.data.message, 'error');
        setIsLoading(false);
      });
  };

  const handleDiscard = () => {
    if (selectedDiscardStatus == null) {
      setDiscardStatusError(true);
      return;
    }
    setIsLoading(true);
    setDiscardStatusError(false);

    let requestUrlDiscard;

    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      requestUrlDiscard = `${process.env.React_App_Base_URL}/api/CRMPendingListing/draft-change-status`;
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      requestUrlDiscard = `${process.env.React_App_Base_URL}/api/CRMPendingListing/listing-change-status`;
    }

    const config = {
      method: 'post',
      url: requestUrlDiscard,
      data: {
        id: listingDetails.ID,
        changedTime: new Date(),
        changeStatusType: ChangeStatusType.Discard,
        status: selectedDiscardStatus,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.status == true) {
          showSnackbar('Listing sent to Discard', 'success');
          setIsLoading(false);
          setOpenDiscardDialog(false);
          handleReloadCRMBoardListings();
          closeListingDialog();
          // setListingDetails();
          setLisitingHistory([]);
          setCallHistory([]);
          setLockListingID(null);
          setLockListingData(null);
        } else {
          showSnackbar(response.data.message, 'error');
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const handleCallUser = () => {
    setIsLoadingCallButton(true);

    let payloadCallUser = {
      phoneNumber: listingDetails?.AppUser?.PhoneNumber,
      CallCreationContext: CallDictionaryKey.AdminSystemCRMSection,
    };

    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      payloadCallUser.draftId = listingDetails?.ID;
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      payloadCallUser.carId = listingDetails?.ID;
    }

    const config = {
      method: 'post',
      url: `${process.env.React_App_Base_URL}/api/Call/make-pbx-outbound-call`,
      data: payloadCallUser,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setSelectedCallDictionaryKey(CallDictionaryKey.AdminSystemCRMSection);
          setIsLoadingCallButton(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingCallButton(false);
      });
  };

  const getListingHistory = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/CRMPendingListing/details-listing-history-list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
      params: {
        AppUserId: listingDetails?.AppUser.ID,
        listingId: listingDetails?.ID,
        privateAdTableTypeId: listingDetails?.PrivateAdTableTypeId,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setLisitingHistory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCallHistory = () => {
    const config = {
      method: 'get',
      url: `${process.env.React_App_Base_URL}/api/CRMPendingListing/details-call-history-list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: user?.Token,
      },
      params: {
        phoneNumber: listingDetails?.AppUser?.PhoneNumber,
        userId: listingDetails?.AppUser?.ID,
      },
    };

    axios(config)
      .then((response) => {
        if (response.status == 200) {
          setCallHistory(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (listingDetails != null && firstRender.current) {
      setIsLoading(true);
      firstRender.current = false;
      getListingHistory();
      getCallHistory();
    }
  }, [listingDetails]);

  useEffect(() => {
    setCheckLaterStatusConfig(listingConfigurations?.CRMCheckLaterStatusTypes);
    setDiscardStatusConfig(listingConfigurations?.DiscardStatusTypes);

    if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
      if (listingDetails?.PrivateAdDraftType == PrivateAdDraftTypes.Basic) {
        setPrivateListingType(PrivateAdTypes.Basic);
      } else {
        setPrivateListingType(null);
      }
    } else if (listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
      setPrivateListingType(listingDetails?.PrivateAdType);
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            width: '12px',
          },
          '::-webkit-scrollbar-track': {
            background: '#f1f1f1',
          },
          '::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
            border: '3px solid #f1f1f1',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 2,
            background: '#f4f6f8',
            width: '100%',
            position: 'sticky',
            top: '0px',
            height: '90px',
            zIndex: 1400,
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              minWidth: 500,
              px: 2,
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <img
                src={`${CustomConfig.imagePathUrl + listingDetails?.Attachments[0]?.CardUrl}`}
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
              />

              <Box>
                <Typography variant='h4' sx={{ fontWeight: 600 }}>
                  {listingDetails?.Brand?.['BrandNameen']} {listingDetails?.Model?.['ModelNameen']}{' '}
                  {listingDetails?.Year.YearName}
                </Typography>
                {listingDetails?.PriceUnit && (listingDetails.Price > 0 || listingDetails.PriceIQD > 0) && (
                  <Box
                    sx={{
                      mt: 1,
                    }}
                  >
                    <Typography variant='h5' sx={{ fontWeight: 600, color: 'red', mt: 1 }}>
                      {listingDetails.PriceUnit == CurrencyType.Dollar && listingDetails.Price > 0
                        ? '$' + CommaSeparator(listingDetails.Price)
                        : ''}
                      {listingDetails.PriceUnit == CurrencyType.IraqiDinar && listingDetails.PriceIQD > 0
                        ? 'IQD ' + CommaSeparator(listingDetails.PriceIQD)
                        : ''}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                  <IoCalendarNumberOutline style={{ width: '1.2em', height: '1.2em' }} />

                  <Typography variant='h6'>
                    {' '}
                    {new Date(listingDetails?.SaveDate + 'Z').toLocaleString('en-GB', {
                      timeZone: 'Asia/Baghdad', // Correct time zone for Baghdad
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    })}
                  </Typography>
                  <Typography variant='h6'>
                    {' '}
                    {new Date(listingDetails?.SaveDate + 'Z').toLocaleString('en-GB', {
                      timeZone: 'Asia/Baghdad', // Correct time zone for Baghdad
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography
                variant='h5'
                sx={{
                  fontWeight: 600,
                  backgroundColor: determineBackgroundColor(listingDetails),
                  color: '#fff',
                  px: 2,
                  py: 0.5,
                  borderRadius: 1,
                  textAlign: 'center',
                }}
              >
                {determineName(listingDetails)?.charAt(0).toUpperCase() + determineName(listingDetails)?.slice(1)}
              </Typography>

              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', my: 1 }}>
                <Box
                  sx={{ display: 'flex', gap: 1, alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    showSnackbar('Copied to clibboard', 'success');
                    navigator.clipboard.writeText(listingDetails?.ID);
                  }}
                >
                  <Typography variant='h5' style={{ fontWeight: '400' }}>
                    {listingDetails?.ID}
                  </Typography>
                  <IoCopyOutline />
                </Box>
                <Divider orientation='vertical' flexItem sx={{ height: 'auto' }} />
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: getStatusColor(checkListingStatus(listingDetails)),
                      borderRadius: '50%',
                    }}
                  ></span>
                  <Typography>{checkListingStatus(listingDetails)}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider orientation='vertical' flexItem sx={{ height: 'auto' }} />

          <Box
            sx={{
              display: 'flex',
              // gap: 8,
              alignItems: 'flex-start',
              minWidth: 500,
              px: 2,
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <GoPerson style={{ width: '1.6em', height: '1.6em' }} />
                  <Typography variant='h5'>{listingDetails?.AppUser.ContactName}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 4,
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <CiGlobe style={{ width: '1.6em', height: '1.6em' }} />

                    <Typography variant='h5'>
                      {listingDetails?.AppUser.LangCode == 'en' && 'English'}
                      {listingDetails?.AppUser.LangCode == 'ar' && 'Arabic'}
                      {listingDetails?.AppUser.LangCode == 'ku' && 'Kurdish'}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <LiaWalletSolid style={{ width: '1.6em', height: '1.6em' }} />

                    <Typography variant='h5'>{listingDetails?.AppUser?.CreditAmount}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                    }}
                  >
                    <PiMapPinLight style={{ width: '1.4em', height: '1.4em' }} />

                    <Typography variant='h5'>{listingDetails?.AppUser?.Location?.['LocationNameen']}</Typography>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                {!isLoadingCallButton && hasCallThroughCallCenterPermission && (
                  <Button
                    onClick={handleCallUser}
                    type='submit'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      // width: '200px',
                      px: 5,
                      height: '30px',
                      background: '#118d57',
                      color: '#fff',
                      '&:hover': {
                        background: '#118d57',
                        '& svg': {
                          color: '#fff',
                        },
                      },
                    }}
                  >
                    <IoCall style={{ width: '1.5em', height: '1.4em' }} />
                    {listingDetails?.AppUser.PhoneNumber}
                  </Button>
                )}

                {isLoadingCallButton && (
                  <Button
                    variant='contained'
                    disabled
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      height: '30px',
                      width: '200px',
                    }}
                  >
                    <CircularProgress size={18} color='inherit' />
                    {listingDetails?.AppUser.PhoneNumber}
                  </Button>
                )}
                {hasCreateManualCallCardPermission && (
                  <Button
                    onClick={() => {
                      setOpenManualCallCardDialog(true);
                      setlistingAndUserUserInfo(listingDetails);
                    }}
                    type='submit'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      height: '30px',
                      px: 5,
                      background: '#222B36',
                      color: '#fff',
                      '&:hover': {
                        background: '#222B36',
                        '& svg': {
                          color: '#fff',
                        },
                      },
                    }}
                  >
                    <IoMdAdd style={{ width: '1.5em', height: '1.4em' }} />
                    Manual Call
                  </Button>
                )}
                {hasAddCreditToUserPermission && (
                  <Button
                    onClick={() => {
                      setOpenAddCreditDialog(true);
                      setlistingAndUserUserInfo(listingDetails);
                    }}
                    type='submit'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      height: '30px',
                      px: 5,
                      background: '#1E3E62',
                      color: '#fff',
                      '&:hover': {
                        background: '#1E3E62',
                        '& svg': {
                          color: '#fff',
                        },
                      },
                    }}
                  >
                    <IoMdAdd style={{ width: '1.5em', height: '1.4em' }} />
                    Add Credit
                  </Button>
                )}
              </Box>
            </Box>
          </Box>

          <Divider orientation='vertical' flexItem sx={{ height: 'auto' }} />
          <Box
            sx={{
              minWidth: 400,
              px: 2,
            }}
          >
            Status:{' '}
            <Typography variant='p' sx={{ fontWeight: 600 }}>
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.NewListing && 'New Listing'}
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.CRMCheckLater && 'CRM Follow Up'}
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.EditorCheckLater && 'Editor Follow Up'}
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.RejectedToCRM && 'Rejected'}
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.FixedAfterRejection &&
                'Fixed After Update Request'}
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.Published && 'Published'}
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.Unpublished && 'Unpublished'}
              {listingDetails?.AdminSystemStatus == AdminSystemStatus.Discarded && 'Discarded'}
            </Typography>
            {listingDetails?.Tickets.length > 0 && (
              <Box sx={{ display: 'flex', gap: 4, mt: 1 }}>
                {listingDetails?.AdminSystemStatus != AdminSystemStatus.CRMCheckLater && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                    }}
                  >
                    Created By:
                    {listingDetails?.Tickets[0]?.TicketCreatorType?.ID == TicketSourceOperatorTypes.SystemAdmin && (
                      <Tooltip title={listingDetails?.Tickets[0]?.CreatedByApplicationUser?.FullName} arrow>
                        <Box
                          sx={{
                            width: 25,
                            height: 25,
                            borderRadius: '50%',
                            background: '#222B36',
                            color: '#fff',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: 12,
                          }}
                        >
                          {listingDetails?.Tickets[0]?.CreatedByApplicationUser?.FullName.slice(0, 2).toUpperCase()}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )}
                {listingDetails?.AdminSystemStatus == AdminSystemStatus.CRMCheckLater && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                    }}
                  >
                    Assigned To:
                    {listingDetails?.Tickets?.[0]?.Assignments?.length > 0 &&
                    listingDetails?.Tickets?.[0]?.Assignments?.[0].AssignmentType?.ID === TicketAssignmentType.User ? (
                      <Tooltip title={listingDetails?.Tickets?.[0]?.Assignments?.[0].ApplicationUser.FullName} arrow>
                        <Box
                          sx={{
                            borderRadius: '50%',
                            background: '#E2EBF5',
                            color: '#2997FF',
                            textAlign: 'center',
                            p: 0.75,
                            cursor: 'pointer',
                            fontSize: 12,
                          }}
                        >
                          {listingDetails?.Tickets?.[0]?.Assignments?.[0].ApplicationUser.FullName.slice(
                            0,
                            2,
                          ).toUpperCase()}
                        </Box>
                      </Tooltip>
                    ) : (
                      listingDetails?.Tickets?.[0]?.Assignments?.[0].AssignmentType?.ID ===
                        TicketAssignmentType.Group && (
                        <Tooltip title={listingDetails?.Tickets?.[0]?.Assignments?.[0].AssignedGroup.Text} arrow>
                          <Box
                            sx={{
                              height: 'fit-content',
                              borderRadius: '10px',
                              background: '#E2EBF5',
                              color: '#2997FF',
                              textAlign: 'center',
                              p: 0.75,
                              cursor: 'pointer',
                              fontSize: 12,
                            }}
                          >
                            {listingDetails?.Tickets?.[0]?.Assignments?.[0].AssignedGroup.Text}
                          </Box>
                        </Tooltip>
                      )
                    )}
                    {listingDetails?.Tickets?.[0]?.Assignments?.length == 0 && (
                      <Tooltip title='CRM' arrow>
                        <Box
                          sx={{
                            height: 'fit-content',
                            borderRadius: '10px',
                            background: '#E2EBF5',
                            color: '#2997FF',
                            textAlign: 'center',
                            p: 0.75,
                            cursor: 'pointer',
                            fontSize: 12,
                          }}
                        >
                          CRM
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )}
                {listingDetails?.Tickets[0]?.TicketSubType != null && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                    }}
                  >
                    Reason:
                    <Chip
                      label={CheckTicketSubType(listingDetails?.Tickets[0])}
                      variant='outlined'
                      size='small'
                      sx={{
                        background: '#fff',
                        color: '#222B36',
                        '&.MuiChip-clickable:hover': {
                          background: '#0000000a',
                          color: '#222B36',
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', mt: 1 }}>
              {listingDetails?.Tickets[0]?.TicketDescription && (
                <Typography>Note: {listingDetails?.Tickets[0]?.TicketDescription}</Typography>
              )}
              {listingDetails?.Tickets[0]?.Comments.length > 0 && (
                <Tooltip arrow title='Comments'>
                  <IconButton
                    aria-label='close'
                    onClick={() => setOpenCommentsDialog(true)}
                    sx={{ p: 0, background: '#fff' }}
                  >
                    <CgNotes style={{ width: '18px', height: '18px' }} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Dialog
              anchor='right'
              open={openCommentsDialog}
              onClose={() => setOpenCommentsDialog(false)}
              maxWidth='100%'
              maxHeight='100%'
            >
              <DialogTitle sx={{ m: 0, p: 3 }} id='customized-dialog-title'>
                <Typography variant='h3' sx={{ my: 1 }}>
                  Comments
                </Typography>
              </DialogTitle>
              <IconButton
                aria-label='close'
                onClick={() => setOpenCommentsDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <IoClose />
              </IconButton>

              <DialogContent dividers sx={{ minHeight: 150, p: 3 }}>
                {listingDetails?.Tickets[0]?.Comments.map((ticketComment, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      background: '#F4F6F8',
                      p: 3,
                      borderRadius: 2,
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        gap: 5,
                      }}
                    >
                      <Box>
                        {ticketComment?.CreatorType?.ID == TicketCommentAdminTypes.System && (
                          <Tooltip title={ticketComment.CreatorType.Text} arrow>
                            <Box
                              // key={index}
                              sx={{
                                width: 30,
                                height: 30,
                                borderRadius: '50%',
                                background: '#0288D1',
                                color: '#fff',
                                textAlign: 'center',
                                p: 1.5,
                                cursor: 'pointer',
                                mb: 2,
                              }}
                            >
                              {ticketComment.CreatorType.Text.slice(0, 2).toUpperCase()}
                            </Box>
                          </Tooltip>
                        )}

                        {ticketComment?.CreatorType?.ID == TicketCommentAdminTypes.SystemAdmin && (
                          <Tooltip title={ticketComment.ApplicationUser.FullName} arrow>
                            <Box
                              // key={index}
                              sx={{
                                width: 30,
                                height: 30,
                                borderRadius: '50%',
                                background: '#0288D1',
                                color: '#fff',
                                textAlign: 'center',
                                p: 1.5,
                                cursor: 'pointer',
                                mb: 2,
                              }}
                            >
                              {ticketComment.ApplicationUser.FullName.slice(0, 2).toUpperCase()}
                            </Box>
                          </Tooltip>
                        )}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            width: 250,
                          }}
                        >
                          {ticketComment?.CreatorType?.ID == TicketCommentAdminTypes.System && (
                            <Typography variant='h5' sx={{ mb: 1 }}>
                              {ticketComment.CreatorType.Text}
                            </Typography>
                          )}
                          {ticketComment?.CreatorType?.ID == TicketCommentAdminTypes.SystemAdmin && (
                            <Typography variant='h5' sx={{ mb: 1 }}>
                              {ticketComment.ApplicationUser.FullName}
                            </Typography>
                          )}
                          {ticketComment?.CommentText && (
                            <Box sx={{ width: 350 }}>
                              <Typography variant='p'>
                                {ticketComment.CommentText.split('\n').map((line, index) => (
                                  <React.Fragment key={index}>
                                    {line}
                                    <br />
                                  </React.Fragment>
                                ))}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Typography
                      variant='h5'
                      sx={{
                        color: '#637381',
                      }}
                    >
                      {new Date(ticketComment?.SaveDate + 'Z').toLocaleString('en-GB', {
                        timeZone: 'Asia/Baghdad', // Correct time zone for Baghdad
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      })}
                    </Typography>
                  </Box>
                ))}
              </DialogContent>
            </Dialog>
          </Box>
          <Box
            sx={{
              minWidth: 400,
              px: 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={() => {
                  closeListingDialog();
                }}
                aria-label='close'
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <IoClose />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Grid
          container
          spacing={7}
          sx={{
            p: 4,
            minHeight: 800,
            // opacity: openCheckLaterStatus || openDiscardStatus ? 0.2 : 1,
          }}
        >
          <Grid item lg={8.5}>
            <CarDetail ref={carDetailRef} listingDetails={listingDetails} saveLisitngError={saveLisitngError} />
          </Grid>
          <Grid item lg={3.5}>
            {!isLoading && listingHistory.length > 0 && <ListingHistory listingHistory={listingHistory} />}
            {isLoading && listingHistory.length == 0 && (
              <Box sx={{ mb: 4 }}>
                <Skeleton variant='rounded' height={75} sx={{ mb: 2 }} />
                <Skeleton variant='rounded' height={75} sx={{ mb: 2 }} />
              </Box>
            )}
            {!isLoading && listingHistory.length == 0 && (
              <Box sx={{ background: '#F5F7F8', p: 2, borderRadius: 2, minHeight: 350 }}>
                <Typography variant='h4'>Listing History</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                  <Typography variant='h4'>No Listings found</Typography>
                </Box>
              </Box>
            )}

            {/* call history */}
            {!isLoading && callHistory.length > 0 && <CallHistory callHistory={callHistory} />}

            {isLoading && callHistory.length == 0 && (
              <Box sx={{ mt: 4 }}>
                <Skeleton variant='rounded' height={75} sx={{ mb: 2 }} />
                <Skeleton variant='rounded' height={75} sx={{ mb: 2 }} />
              </Box>
            )}

            {!isLoading && callHistory.length == 0 && (
              <Box sx={{ background: '#F5F7F8', p: 2, borderRadius: 2, minHeight: 350, mt: 2 }}>
                <Typography variant='h4'>Call History</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                  <Typography variant='h4'>No Calls found</Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            background: '#f4f6f8',
            width: '100%',
            position: 'sticky',
            bottom: '0px',
            height: '65px',
            zIndex: '2',
            p: 5,
            // opacity: openCheckLaterStatus || openDiscardStatus ? 0.2 : 1,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: 2,
            }}
          >
            {listingDetails?.AdminSystemStatus != AdminSystemStatus.RejectedToCRM &&
              listingDetails?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable &&
              hasEditPermission && (
                <Button
                  onClick={() => setOpenDiscardDialog(true)}
                  type='submit'
                  sx={{
                    my: 5,
                    width: '150px',
                    background: '#E72929',
                    color: '#fff',
                    '&:hover': {
                      background: '#E72929',
                      '& svg': {
                        color: '#fff',
                      },
                    },
                  }}
                >
                  Discard
                </Button>
              )}

            <Dialog
              anchor='right'
              open={openDiscardDialog}
              onClose={() => setOpenDiscardDialog(false)}
              maxWidth='100%'
              maxHeight='100%'
            >
              <DialogTitle sx={{ m: 0, p: 3 }} id='customized-dialog-title'>
                <Typography variant='h3' sx={{ my: 1 }}>
                  Discard
                </Typography>
              </DialogTitle>
              <IconButton
                aria-label='close'
                onClick={() => setOpenDiscardDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <IoClose />
              </IconButton>

              <DialogContent dividers sx={{ minHeight: 150, p: 3 }}>
                <Typography variant='h5' sx={{ fontWeight: '400' }}>
                  Select reason to discard
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    mt: 2,
                  }}
                >
                  {discardStatusConfig.map((discardStatus, index) => (
                    <Chip
                      key={index}
                      label={discardStatus.Text}
                      variant='outlined'
                      size='large'
                      sx={{
                        background: discardStatus.ID == selectedDiscardStatus ? '#222B36' : '#fff',

                        color: discardStatus.ID == selectedDiscardStatus ? '#fff' : '#222B36',

                        '&.MuiChip-clickable:hover': {
                          background: discardStatus.ID == selectedDiscardStatus ? '#222B36' : '#0000000a',

                          color: discardStatus.ID == selectedDiscardStatus ? '#fff' : '#222B36',
                        },
                      }}
                      onClick={() => setSelectedDiscardStatus(discardStatus.ID)}
                    />
                  ))}
                </Box>
                {!selectedDiscardStatus && discardStatusError && (
                  <FormHelperText sx={{ color: 'red' }}>Discard Status is Required</FormHelperText>
                )}
              </DialogContent>
              <DialogActions
                sx={{
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button
                    onClick={() => setOpenDiscardDialog(false)}
                    type='submit'
                    sx={{
                      width: '150px',
                      background: '#fff',
                      color: '#222B36',
                      border: '1px solid #ccc',
                      '&:hover': {
                        background: '#fff',
                        '& svg': {
                          color: '#222B36',
                        },
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  {!isLoading && (
                    <Button
                      onClick={handleDiscard}
                      type='submit'
                      sx={{
                        width: '150px',
                        background: '#222B36',
                        color: '#fff',
                        '&:hover': {
                          background: '#222B36',
                          '& svg': {
                            color: '#fff',
                          },
                        },
                      }}
                    >
                      Confirm
                    </Button>
                  )}

                  {isLoading && (
                    <Button
                      variant='contained'
                      disabled
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '150px',
                        gap: 2,
                      }}
                    >
                      <CircularProgress size={18} color='inherit' />
                      Confirm
                    </Button>
                  )}
                </Box>
              </DialogActions>
            </Dialog>
            {listingDetails?.AdminSystemStatus != AdminSystemStatus.RejectedToCRM && hasEditPermission && (
              <Button
                onClick={() => setOpenCheckLaterDialog(true)}
                type='submit'
                sx={{
                  my: 5,
                  width: '200px',
                  background: '#4158A6',
                  color: '#fff',
                  '&:hover': {
                    background: '#4158A6',
                    '& svg': {
                      color: '#fff',
                    },
                  },
                }}
              >
                Follow up later
              </Button>
            )}

            <Dialog
              anchor='right'
              open={openCheckLaterDialog}
              onClose={() => setOpenCheckLaterDialog(false)}
              maxWidth='100%'
              maxHeight='100%'
            >
              <DialogTitle sx={{ m: 0, p: 3 }} id='customized-dialog-title'>
                <Typography variant='h3' sx={{ my: 1 }}>
                  Follow up later
                </Typography>
              </DialogTitle>
              <IconButton
                aria-label='close'
                onClick={() => setOpenCheckLaterDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <IoClose />
              </IconButton>

              <DialogContent dividers sx={{ p: 3 }}>
                <Typography variant='h5' sx={{ fontWeight: '400' }}>
                  Select reason to Follow Up
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    mt: 2,
                  }}
                >
                  {checkLaterStatusConfig.map((checkLaterStatus, index) => (
                    <Chip
                      key={index}
                      label={checkLaterStatus.Text}
                      variant='outlined'
                      size='large'
                      sx={{
                        background: checkLaterStatus.ID == selectedCheckLaterStatus ? '#222B36' : '#fff',

                        color: checkLaterStatus.ID == selectedCheckLaterStatus ? '#fff' : '#222B36',

                        '&.MuiChip-clickable:hover': {
                          background: checkLaterStatus.ID == selectedCheckLaterStatus ? '#222B36' : '#0000000a',

                          color: checkLaterStatus.ID == selectedCheckLaterStatus ? '#fff' : '#222B36',
                        },
                      }}
                      onClick={() => setSelectedCheckLaterStatus(checkLaterStatus.ID)}
                    />
                  ))}
                </Box>
                {!selectedCheckLaterStatus && checkLaterError && (
                  <FormHelperText sx={{ color: 'red', mt: 2 }}>Follow Up reason is Required</FormHelperText>
                )}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    label='Callback Reminder'
                    value={taskStartDate}
                    onChange={(newValue) => {
                      const selectedTime = dayjs(newValue);
                      const utcTime = selectedTime.utc();
                      setTaskStartDate(utcTime);
                    }}
                    minDateTime={dayjs()}
                    sx={{ mt: 6 }}
                  />
                </LocalizationProvider>
                {hasChangeFollowUpAssignmentPermission && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      my: 4,
                    }}
                  >
                    <FormControl fullWidth sx={{ minHeight: 50 }}>
                      <InputLabel id='demo-simple-select-label'>Assignment Type</InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        label='Assignment Type'
                        value={checkLaterAssignments.assignmentTypeId || ''}
                        onChange={(e) => handleTypeChange(parseInt(e.target.value, 10))}
                        MenuProps={{
                          disableScrollLock: true,
                        }}
                      >
                        {TicketAssignmentTypes.map((assignmentType, idx) => (
                          <MenuItem key={idx} value={assignmentType.ID}>
                            {assignmentType.Text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ minHeight: 50 }}>
                      <Autocomplete
                        id='search-select'
                        options={
                          checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User
                            ? crmUsers // Show CRM users when User type is selected
                            : [crmGroup] // Show CRM group as a single option when Group type is selected
                        }
                        getOptionLabel={(option) =>
                          checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User
                            ? option.FullName
                            : option.GroupName
                        }
                        isOptionEqualToValue={(option, value) =>
                          checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User
                            ? option.Id === value
                            : option.ID === value
                        }
                        value={
                          checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User &&
                          checkLaterAssignments.applicationUserId
                            ? crmUsers.find((user) => user.Id === checkLaterAssignments.applicationUserId)
                            : checkLaterAssignments.assignmentTypeId === TicketAssignmentType.Group &&
                              checkLaterAssignments.assignedGroupId
                            ? crmGroup
                            : null
                        }
                        onChange={(e, newValue) => handleSelectionChange(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={
                              checkLaterAssignments.assignmentTypeId === TicketAssignmentType.User
                                ? 'Select CRM User'
                                : 'Select Group'
                            }
                            variant='outlined'
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                )}

                <TextField
                  id='outlined-multiline-static'
                  label='Note'
                  multiline
                  rows={4}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  sx={{ width: '100%', mt: 6 }}
                />
              </DialogContent>
              <DialogActions sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Button
                    onClick={() => setOpenCheckLaterDialog(false)}
                    type='submit'
                    sx={{
                      width: '150px',
                      background: '#fff',
                      color: '#222B36',
                      border: '1px solid #ccc',
                      '&:hover': {
                        background: '#fff',
                        '& svg': {
                          color: '#222B36',
                        },
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  {!isLoading && (
                    <Button
                      onClick={handleCheckLater}
                      type='submit'
                      sx={{
                        width: '150px',
                        background: '#222B36',
                        color: '#fff',
                        '&:hover': {
                          background: '#222B36',
                          '& svg': {
                            color: '#fff',
                          },
                        },
                      }}
                    >
                      Confirm
                    </Button>
                  )}

                  {isLoading && (
                    <Button
                      variant='contained'
                      disabled
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '150px',
                        gap: 2,
                      }}
                    >
                      <CircularProgress size={18} color='inherit' />
                      Confirm
                    </Button>
                  )}
                </Box>
              </DialogActions>
            </Dialog>
          </Box>

          <Dialog
            anchor='right'
            open={openPublishStatusDialog}
            onClose={() => setOpenPublishStatusDialog(false)}
            maxWidth='100%'
            maxHeight='100%'
          >
            <DialogTitle sx={{ m: 0, p: 3 }} id='customized-dialog-title'>
              <Typography variant='h4' sx={{ my: 1, fontWeight: 600 }}>
                Publish Listing
              </Typography>
            </DialogTitle>
            <IconButton
              aria-label='close'
              onClick={() => setOpenPublishStatusDialog(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <IoClose />
            </IconButton>

            <DialogContent dividers sx={{ minHeight: 150, p: 3 }}>
              <Typography variant='h5' sx={{ fontWeight: '400', mb: 1 }}>
                Select Package
              </Typography>

              <RadioGroup
                row
                aria-labelledby='demo-radio-buttons-group-label'
                name='radio-buttons-group'
                defaultValue={privateListingType}
              >
                <Tooltip
                  title={
                    !publishStatus?.userHasFreeListing
                      ? 'User is not eligible for a free listing'
                      : !publishStatus?.locationHasFreePrivateAdPackage
                      ? 'Location does not support free listings'
                      : 'Free Listing Available'
                  }
                  arrow
                >
                  <FormControlLabel
                    value={PrivateAdTypes.Basic}
                    sx={{
                      opacity:
                        publishStatus?.userHasFreeListing && publishStatus?.locationHasFreePrivateAdPackage
                          ? '1'
                          : '0.5',
                      cursor:
                        publishStatus?.userHasFreeListing && publishStatus?.locationHasFreePrivateAdPackage
                          ? 'pointer'
                          : 'inherit',
                    }}
                    control={
                      <Radio
                        disabled={!publishStatus?.userHasFreeListing || !publishStatus?.locationHasFreePrivateAdPackage}
                        sx={{
                          color: '#222B36',
                          '&.Mui-checked': {
                            color: '#222B36',
                          },
                        }}
                        onClick={() => {
                          if (publishStatus?.userHasFreeListing && publishStatus?.locationHasFreePrivateAdPackage) {
                            setPrivateListingType(PrivateAdTypes.Basic);
                          }
                        }}
                      />
                    }
                    label={
                      <Box sx={{ width: 100 }}>
                        <Typography
                          variant='h6'
                          sx={{
                            fontWeight: 600,
                            backgroundColor: '#333333',
                            color: '#fff',
                            px: 2,
                            py: 1,
                            borderRadius: 1,
                            textAlign: 'center',
                          }}
                        >
                          Free Listing
                        </Typography>
                      </Box>
                    }
                  />
                </Tooltip>
                <FormControlLabel
                  value={PrivateAdTypes.Boost}
                  control={
                    <Radio
                      sx={{
                        color: '#222B36',
                        '&.Mui-checked': {
                          color: '#222B36',
                        },
                      }}
                      onClick={() => {
                        setPrivateListingType(PrivateAdTypes.Boost);
                      }}
                    />
                  }
                  label={
                    <Box sx={{ width: 100 }}>
                      <Typography
                        variant='h5'
                        sx={{
                          fontWeight: 600,
                          backgroundColor: '#F08800',
                          color: '#fff',
                          px: 2,
                          py: 1,
                          borderRadius: 1,
                          textAlign: 'center',
                        }}
                      >
                        Boost
                      </Typography>
                    </Box>
                  }
                />
                <FormControlLabel
                  value={PrivateAdTypes.SuperBoost}
                  control={
                    <Radio
                      sx={{
                        color: '#222B36',
                        '&.Mui-checked': {
                          color: '#222B36',
                        },
                      }}
                      onClick={() => {
                        setPrivateListingType(PrivateAdTypes.SuperBoost);
                      }}
                    />
                  }
                  label={
                    <Box sx={{ width: 100 }}>
                      <Typography
                        variant='h6'
                        sx={{
                          fontWeight: 600,
                          backgroundColor: '#00A520',
                          color: '#fff',
                          px: 2,
                          py: 1,
                          borderRadius: 1,
                          textAlign: 'center',
                        }}
                      >
                        Super Boost
                      </Typography>
                    </Box>
                  }
                />
              </RadioGroup>
              {privateListingType == null && publishListingError && (
                <FormHelperText sx={{ color: 'red', mt: 1 }}>Package is Required</FormHelperText>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                  gap: 2,
                  mt: 2,
                }}
              >
                <Button
                  onClick={() => setOpenPublishStatusDialog(false)}
                  type='submit'
                  sx={{
                    width: '150px',
                    background: '#fff',
                    color: '#222B36',
                    border: '1px solid #ccc',
                    '&:hover': {
                      background: '#fff',
                      '& svg': {
                        color: '#222B36',
                      },
                    },
                  }}
                >
                  Cancel
                </Button>
                {!isLoadingPublishButton && (
                  <Button
                    onClick={() => {
                      setIsLoadingPublishButton(true);
                      const carDetailData = carDetailRef.current.getAllData();
                      handleSaveOnly(carDetailData, 'publish');
                    }}
                    type='submit'
                    sx={{
                      width: '150px',
                      background: '#222B36',
                      color: '#fff',
                      '&:hover': {
                        background: '#222B36',
                        '& svg': {
                          color: '#fff',
                        },
                      },
                    }}
                  >
                    Confirm
                  </Button>
                )}
                {isLoadingPublishButton && (
                  <Button
                    variant='contained'
                    disabled
                    sx={{
                      display: 'flex',
                      gap: 2,
                      alignItems: 'center',
                      width: '150px',
                    }}
                  >
                    <CircularProgress size={18} color='inherit' />
                    Confirm
                  </Button>
                )}
              </Box>
            </DialogActions>
          </Dialog>

          <Dialog
            anchor='right'
            open={openPublishConfirmationDialog}
            onClose={() => setOpenPublishConfirmationDialog(false)}
            maxWidth='100%'
            maxHeight='100%'
          >
            <Box sx={{ minWidth: 500 }}>
              <DialogTitle sx={{ m: 0, p: 3 }} id='customized-dialog-title'>
                <Typography variant='h3' sx={{ my: 1 }}>
                  Publish to Editor
                </Typography>
              </DialogTitle>
              <IconButton
                aria-label='close'
                onClick={() => setOpenPublishConfirmationDialog(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <IoClose />
              </IconButton>

              <DialogContent dividers sx={{ minHeight: 100 }}>
                <TextField
                  id='outlined-multiline-static'
                  label='Note (Optional)'
                  multiline
                  rows={4}
                  value={publishRejectedListingComment}
                  onChange={(e) => setPublishRejectedListingComment(e.target.value)}
                  sx={{ width: '100%', mt: 4 }}
                />
              </DialogContent>
              <DialogActions>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '10px',
                      p: 2,
                    }}
                  >
                    <Button
                      onClick={() => setOpenPublishConfirmationDialog(false)}
                      type='submit'
                      sx={{
                        width: '150px',
                        background: '#fff',
                        color: '#222B36',
                        border: '1px solid #ccc',
                        '&:hover': {
                          background: '#fff',
                          '& svg': {
                            color: '#222B36',
                          },
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    {!isLoadingPublishButton && (
                      <Button
                        onClick={() => {
                          setIsLoadingPublishButton(true);
                          const carDetailData = carDetailRef.current.getAllData();
                          handleSaveOnly(carDetailData, 'publish');
                        }}
                        type='submit'
                        sx={{
                          width: '150px',
                          background: '#222B36',
                          color: '#fff',
                          '&:hover': {
                            background: '#222B36',
                            '& svg': {
                              color: '#fff',
                            },
                          },
                        }}
                      >
                        Confirm
                      </Button>
                    )}
                    {isLoadingPublishButton && (
                      <Button
                        variant='contained'
                        disabled
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          width: '150px',
                        }}
                      >
                        <CircularProgress size={18} color='inherit' />
                        Confirm
                      </Button>
                    )}
                  </Box>
                </Box>
              </DialogActions>
            </Box>
          </Dialog>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              gap: 2,
            }}
          >
            {!isLoadingSaveOnlyButton && hasEditPermission && (
              <Button
                onClick={handleOnlySaveButton}
                type='submit'
                sx={{
                  my: 5,
                  width: '150px',
                  background: '#fff',
                  color: '#222B36',
                  border: '1px solid #ccc',
                  '&:hover': {
                    background: '#fff',
                    '& svg': {
                      color: '#222B36',
                    },
                  },
                }}
              >
                Save Only
              </Button>
            )}
            {isLoadingSaveOnlyButton && hasEditPermission && (
              <Button
                variant='contained'
                disabled
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 5,
                  width: '150px',
                  gap: 2,
                }}
              >
                <CircularProgress size={18} color='inherit' />
                Save Only
              </Button>
            )}
            {hasPublishPermission && listingDetails?.AdminSystemStatus != AdminSystemStatus.Published && (
              <Button
                onClick={() =>
                  listingDetails?.AdminSystemStatus == AdminSystemStatus.RejectedToCRM
                    ? setOpenPublishConfirmationDialog(true)
                    : handleLoadPublishStatus()
                }
                type='submit'
                sx={{
                  my: 5,
                  width: '150px',
                  background: '#222B36',
                  color: '#fff',
                  '&:hover': {
                    background: '#222B36',
                    '& svg': {
                      color: '#fff',
                    },
                  },
                }}
              >
                Publish to Editor
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ListingDetail;
