export const UserHasPermission = (
  permitedPermissionKey,
  userPermissions,
  requireSuperAdmin = false,
) => {
  if (userPermissions?.IsSuperAdmin) {
    return true;
  }

  if (requireSuperAdmin == true) {
    return false;
  }
  if (permitedPermissionKey && permitedPermissionKey != '') {
    if (userPermissions?.PermissionKeys?.includes(permitedPermissionKey)) {
      return true;
    }
    return false;
  }
  return true;
};
