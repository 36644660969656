import React, { useEffect, useRef, useState } from 'react';
// import 'tui-image-editor/dist/tui-image-editor.css';
// import ImageEditor from '@toast-ui/react-image-editor';
import PropTypes from 'prop-types';

import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';

const CustomImageEditor = ({ image, updateImage, index, setDialogImagedEditor }) => {
  // const myTheme = {
  //   'header.display': 'none',
  // };

  // const imageEditorRef = useRef(null); // Create a ref for the ImageEditor

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  useEffect(() => {
    window.addEventListener('unhandledrejection', (event) => {
      console.warn('Unhandled promise rejection:', event.promise, 'reason:', event);
    });
  }, []);

  return (
    <>
      <div style={{ width: '100%', height: '100%', zIndex: '1' }}>
        <FilerobotImageEditor
          source={image?.original || image?.url}
          onModify={(designState) => console.log('modify', designState)}
          onSave={(editedImageObject, designState) => {
            var blob = dataURItoBlob(editedImageObject.imageBase64);

            updateImage(blob, index);
          }}
          closeAfterSave={true}
          showBackButton={true}
          disableSaveIfNoChanges={true}
          avoidChangesNotSavedAlertOnLeave={true}
          onBeforeSave={() => false}
          onClose={() =>
            setDialogImagedEditor({
              open: false,
              photo: null,
              index: null,
            })
          }
          useBackendTranslations={false}
          annotationsCommon={{
            fill: '#ff0000',
          }}
          Rotate={{ angle: 90, componentType: 'buttons' }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE]}
          defaultToolId={TOOLS?.CROP} //
          Crop={{
            ratio: 'custom', // Custom crop settings
          }}
        />
      </div>
    </>
  );
};

export default CustomImageEditor;

CustomImageEditor.propTypes = {
  image: PropTypes.object,
  index: PropTypes.number,
  updateImage: PropTypes.func,
  setDialogImagedEditor: PropTypes.func,
};
