import React, { useEffect } from 'react';
import { useUrlSearchParams } from 'use-url-search-params';
import AppContentView from '@crema/components/AppContentView';
import generateRoutes from '@crema/helpers/RouteGenerator';
import { Layouts } from '@crema/components/AppLayout';
import { useAuthMethod, useAuthUser } from '@crema/hooks/AuthHooks';
import { useLayoutActionsContext, useLayoutContext } from '@crema/context/AppContextProvider/LayoutContextProvider';
import { useSidebarActionsContext } from '@crema/context/AppContextProvider/SidebarContextProvider';
import { anonymousStructure, authorizedStructure, publicStructure } from '../AppRoutes';
import { useRoutes } from 'react-router-dom';
import routesConfig from '../AppRoutes/routeConfig';
import { initialUrl } from '@crema/constants/AppConst';
import { useUserPermissions } from '@crema/context/AppContextProvider/PermissionContextProvider';
import axios from 'axios';
import AppLoader from '@crema/components/AppLoader';
import SystemHubConnection from '@crema/services/Hub/SystemHubConnection';
import InitialLoader from '@crema/components/InitialLoader';

const AppLayout = () => {
  const { navStyle } = useLayoutContext();
  const { userPermissions, setUserPermissions, setPermissionIsLoading, isPermissionLoading } = useUserPermissions();
  const { user, isAuthenticated } = useAuthUser();
  const { customLogout } = useAuthMethod();
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams();

  const initURL = params?.redirect ? params?.redirect : initialUrl;
  const loginUrl = `/signin`;
  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated,
    userRole: 'user',
    anonymousStructure: anonymousStructure(initURL),
    authorizedStructure: authorizedStructure(loginUrl),
    publicStructure: publicStructure(initURL),
  });

  const routes = useRoutes(generatedRoutes);
  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout);
    if (params.menuStyle) updateMenuStyle(params.menuStyle);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [params.layout, params.menuStyle, params.sidebarImage, updateNavStyle, updateMenuStyle, setSidebarBgImage]);

  useEffect(() => {
    const fetchPermissions = async () => {
      const config = {
        method: 'get',
        url: `${process.env.React_App_Base_URL}/api/User/user-permissions`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: user?.Token,
        },
      };

      axios(config)
        .then((response) => {
          if (response.status == 200) {
            setUserPermissions(response.data);
            setPermissionIsLoading(false);
          } else if (response.status == 401) {
            customLogout();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            customLogout();
          }
          setPermissionIsLoading(false);
        });
    };

    if (!userPermissions && isAuthenticated) {
      fetchPermissions();
    }
  }, []);

  if (isPermissionLoading && isAuthenticated) {
    return <InitialLoader />;
  }

  return (
    <>
      {isAuthenticated ? <AppLayout routes={routes} routesConfig={routesConfig} /> : <AppContentView routes={routes} />}
    </>
  );
};

export default AppLayout;
